Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.netSuiteUrlValidation = "https://4496721-sb1.restlets.api.netsuite.com/app/site/hosting/restlet.nl?script=924&deploy=1"

exports.emailValidRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/
exports.getUserInfoPostEndPoint = "bx_block_login/logins/sso_operation";
exports.otpEndPoint = "bx_block_login/logins/verify_otp";

exports.emailVerificationLoginApiEndPoint = "bx_block_login/logins/email_verification";
exports.verifyLoginOtpApiEndPoint = "bx_block_login/logins/verify_otp";
exports.resendOtpApiEndPoint = "bx_block_forgot_password/otps";
// Customizable Area End