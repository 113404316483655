import React from 'react'
import { Modal, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createTheme } from "@material-ui/core/styles";

const GreenCheck = require('./green_check.png');


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
const useStyles = makeStyles(() => ({
    profileSuccessModalWrapper: {
        '& div:first-child': {
            // backgroundColor: 'rgba(255, 255, 255, 0.90) !important'
        }
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        color: 'red',
        cursor: 'pointer'
    },
    logoutBtnStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: '#FFFFFF',
        padding: "10px 16px",
        backgroundColor: '#DA291C'
    },
}));

const userProfileModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "400px",
    width:"100%",
    height: 200,
    bgcolor: 'background.paper',
    // border: '0.5px solid #000',
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderBottomRightRadius: 20,
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Inter', sans-serif",
    "@media(max-width: 767px)": {
        width: '70%'
      },
};

const logoutBtnContainer = {
    padding: '15px 0px',
    cursor: "pointer"
}

interface StatusNotifyModalProps {
    isModalOpen: boolean,
    handleModalClose?: () => void,
    modalMessage: string,
    isSessionExpired: boolean,
    handleNavigationPath: (path: string) => void,
}


const StatusNotifyModal: React.FC<StatusNotifyModalProps> = ({ 
    isModalOpen,
    handleModalClose,
    modalMessage,
    isSessionExpired,
    handleNavigationPath
}) => { 
    const classes = useStyles();

    const handleCloseIcon = () =>{
        if(isSessionExpired){
            localStorage.clear();
            handleNavigationPath("Home")
        }
        else{
            handleModalClose && handleModalClose()
        }
    }
  return (
    <Modal
        className={classes.profileSuccessModalWrapper}
        open={isModalOpen}
        onClose={handleModalClose}
        data-test-id="statusNotifyModalId"
    >
        <Box sx={userProfileModalStyle}>
            <IconButton
                aria-label="Close"
                className={classes.modalCloseButton}
                onClick={handleCloseIcon}
                data-test-id="profileSuccessModalIconCloseId"
            >
                <CloseIcon />
            </IconButton>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <img src={GreenCheck} style={{ width: '15%' }} />
                <div style={{ marginTop: "20px" }}>{modalMessage}</div>
            </div>
            {isSessionExpired &&
                <Box sx={logoutBtnContainer}>
                    <div data-test-id="profileFailModalId" onClick={handleCloseIcon}
                        className={classes.logoutBtnStyle}>Logout</div>
                </Box>
            }
        </Box>
    </Modal>
  )
}

export default StatusNotifyModal