import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const GifLoader = require('./gifloader.png');
interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color:'red'
  },
  gifLoader: {
    width: "80px",  
    height: "80px",
  }
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
      <img src={GifLoader} alt="Loading..." className={classes.gifLoader} />
      </div>
    </div>
  ) : (
    <div />
  );
}
