import React from "react";

// Customizable Area Start
import {
  Box,
  InputBase
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
import { imgLogo, imgLogoRound, imgLogoText, clockImg } from './assets';
import OtpInput from "react-otp-input";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area Start
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E0DE',
    padding: '20px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  error: {
    '& input': {
      borderColor: 'red !important',
    },
  }
}))(InputBase);
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
       // Customizable Area Start
      // Required for all blocks
      <>
        {this.state.showLoader && <Loader loading={true}/> }
      <ThemeProvider theme={theme}>
        <Box className={`${classes.container}`}>
          <Box className={classes.emailContainer}>
            <Box className="body">
              <Box className="header" style={{ marginBottom: '40px' }}>
                <img src={imgLogo} />
                <img style={{ marginLeft: '10px' }} src={imgLogoText} />
              </Box>
              {this.state.step === "email" && <Box className="form">
                <div style={{ color: '#3D3935', fontSize: 24, fontFamily: 'Inter', fontWeight: 700, marginBottom: '40px' }}>Log In</div>
                <div style={{ color: '#3D3935', fontSize: 14, fontFamily: 'Inter !important', fontWeight: 700, marginBottom: '4px' }}>Email Address</div>
                <BootstrapInput data-test-id={"emailInputId"} error={this.state.hasErrorEmail} placeholder="Enter your email address" onChange={(event) => this.handleEmailChange(event)} fullWidth  value={this.state.email}/>
                {this.state.hasErrorEmail && <div style={{ marginTop: '2px', color: '#DC2626', fontSize: 12, fontFamily: 'Inter', fontWeight: 400, lineHeight: "18px" }}>{this.state.emailError}</div>}
                <div onClick={() => this.handleEmailSubmit()} data-test-id={"txtNext"} style={{ fontWeight: 700, cursor: 'pointer', marginTop: '24px', color: 'white', width: '100%', padding: '10px 0px', background: '#DA291C', borderRadius: 8, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                  Next
                </div>
              </Box>}

              {
                this.state.step === "otp" &&
                <Box className={classes.OTPContainer}>
                  <div style={{ color: '#3D3935', fontSize: 24, fontFamily: 'Inter', fontWeight: 700, marginBottom: '8px' }}>OTP Verification</div>
                  <div style={{ color: '#7D7A78', fontSize: 16, fontFamily: 'Inter', fontWeight: 400, marginBottom: '32px' }}>Enter the 6-digit code that we sent to your email</div>
                  <div className={classes.customOTP}>
                    <OtpInput
                      data-test-id={"txtdata"}
                      onChange={(otp) => this.setState({ otpValue: otp })}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                      value={this.state.otpValue}
                    />
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '8px', alignItems: 'center' }}>
                    <img src={clockImg} />
                    <div style={{ marginLeft: '2px', color: '#7D7A78', fontSize: 16, fontFamily: 'Inter', fontWeight: 700, width: "50px" }}>{this.formatTime(this.state.time)}</div>
                  </div>
                  {this.state.hasErrorOtp && <div style={{ width: '100%', marginTop: '15px', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                    <div style={{ color: '#DC2626', fontSize: 16, fontFamily: 'Inter', fontWeight: 400 }}>Invalid OTP</div>
                  </div>}
                  <div onClick={() => this.handleOtpSubmit()} data-test-id={"txtSubmit"}style={{ cursor: 'pointer', width: '100%', paddingTop: 10, paddingBottom: 10, background: '#DA291C', borderRadius: 8, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex', margin: '32px 0px' }}>
                    <div style={{ color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>
                      {this.state.isResendOtp ? "Resend OTP": "Verify"}
                    </div>
                  </div>
                  <div 
                    onClick={() => this.handleBackBtn()} 
                    data-test-id={"goBackBtnId"} 
                    style={{ cursor: 'pointer', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}
                  >
                    <div  style={{ color: '#3D3935', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>Go Back</div>
                  </div>
                </Box>
              }
            </Box>
            <Box className="footer">
              <div style={{ width: '70%', wordBreak: 'break-word', fontSize: '12px', fontWeight: 400 }}>
                If you're a CIS parent or prior camp attendee (or registrant), you're already in our system, so sign in using the credentials provided previously. Don't know what they are? No problem, email us at <span style={{ color: '#DA291C' }}>holidaycamps@cis.edu.sg</span> and we'll get you sorted.
              </div>
            </Box>
          </Box>
          <Box className={classes.logoContainer}>
            <img src={imgLogoRound} />
            <div style={{ marginTop: '53px', color: 'white', fontSize: 36, fontFamily: 'Inter', fontWeight: 700 }}>Own the future.</div>
          </Box>
        </Box>
      </ThemeProvider>
      </>

       // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = createStyles({
  container: {
    '& *': {
      fontFamily: "'Inter', sans-serif",
      boxSizing: 'border-box'
    },
    minWidth: '100vw',
    minHeight: '100vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    '@media (max-width: 992px)': {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    }
  },
  error: {
    '& input': {
      borderColor: 'red !important'
    }
  },
  emailContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    "& .header": {
      marginTop: 20,
      marginLeft: 20
    },
    "& .body": {
      height: 'calc(100vh - 70px)',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .form': {
        width: '50%',
        padding: 40,
        '@media (max-width: 680px)': {
          width: '80%'
        }
      }
    },
    '& .footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#DA291C',
    borderRadius: '0 0 0 60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 992px)': {
      display: 'none'
    }
  },
  OTPContainer: {
    width: '50%',
    '@media (max-width: 680px)': {
      width: '80%'
    }
  },
  customOTP: {
    '& input': {
      width: '20% !important',
      aspectRatio: '1/1',
      borderRadius: '8px',
      border: '1px #E0E0DE solid',
      marginRight: 20,
      fontSize: '16px'
    },
    "& input:last-child": {
      marginRight: 0
    }
  },
})

export const EmailAccountBlock = withStyles(webStyle)(EmailAccountLoginBlock)
// Customizable Area End
