export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const homeicon = require('../assets/home.png');
export const phoneimage = require('../assets/imageContact.png');
export const dollaricon = require('../assets/dollar.png');
export const studentimg = require('../assets/student.png');
export const closeicon = require('../assets/image_close.png');
export const arrowdown = require('../assets/arrowdown.png');
export const arrowback = require('../assets/arrowback.png');
export const baseline = require('../assets/baseline.png');
export const cameraImage = require('../assets/cameraImg.png');
export const greenCheckImage = require('../assets/green_check.png');
export const hamburgerIcon = require('../assets/hamburger.png')
