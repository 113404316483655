import React from 'react'
import { Modal, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createTheme } from "@material-ui/core/styles";

const paymentSuccess = require('./payment_success.png');
const paymentError = require('./payment_error.png');

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const useStyles = makeStyles(() => ({
    profileSuccessModalWrapper: {
        '& div:first-child': {
            // backgroundColor: 'rgba(255, 255, 255, 0.90) !important'
        }
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        color: 'red',
    },
    logoutBtnStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: '#FFFFFF',
        padding: "10px 16px",
        backgroundColor: '#DA291C',
        borderRadius:"5px"
    },
}));

const userProfileModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "400px",
    width:"100%",
    height: 240,
    bgcolor: 'background.paper',
    // border: '0.5px solid #000',
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: "5px 5px 20px 5px",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Inter', sans-serif",
    "@media(max-width: 767px)": {
        width: '70%'
      },
};

const paymentFailedBtnContainer = {
    padding: '15px 0px'
}

interface PaymentResponseModalProps {
    isModalOpen: boolean,
    handleModalClose?: () => void,
    modalMessage: string,
    isPaymentFailed: boolean,
    refundMessage: boolean,
    handlePayAgain: () => void
}


const PaymentResponseModal: React.FC<PaymentResponseModalProps> = ({ 
    isModalOpen,
    handleModalClose,
    modalMessage,
    isPaymentFailed,
    refundMessage,
    handlePayAgain
}) => { 
    const classes = useStyles();

  return (
    <Modal
        className={classes.profileSuccessModalWrapper}
        open={isModalOpen}
        onClose={handleModalClose}
        data-test-id="paymentResponseModalId"
    >
        <Box sx={userProfileModalStyle} style={{height : isPaymentFailed ? 240 : 200}}>
            <IconButton
                aria-label="Close"
                className={classes.modalCloseButton}
                onClick={handleModalClose}
                data-test-id="paymentResponseModalIconCloseId"
            >
                <CloseIcon />
            </IconButton>
            <div style={{ textAlign: "center", marginTop: "40px" }}>
                <img src={isPaymentFailed ? paymentError : paymentSuccess} style={{ width: "15%" }} />
                <div style={{ marginTop: "13px", fontSize:"20px" }}>{modalMessage}</div>
                {refundMessage && <div style={{ fontSize:"20px" }}>Submitted Successfully</div>}
            </div>
            {isPaymentFailed &&
            <>
                <Box>
                    <div style={{fontSize: "14px", color: "gray", marginTop: "5px", fontWeight: 'lighter', marginBottom:'20px'}}>
                        Your transaction has been failed due to some technical error. Please try again.
                    </div>
                    <Box sx={{borderBottom: '1px solid #ECEBEB',width: '100%'}}></Box>
                </Box>
                <Box sx={paymentFailedBtnContainer}>
                    <div data-test-id="paymentErrorModalId" onClick={handlePayAgain}
                        className={classes.logoutBtnStyle}>Pay Again</div>
                </Box>
            </>
            }
        </Box>
    </Modal>
  )
}

export default PaymentResponseModal