import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getUserName } from "../../utilities/src/Colors";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: any;
  email: string;
  phoneNumber: string;
  comments: string;
  subject: string;
  enableField: boolean;
  Detail: string;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  fieldError: boolean;
  fieldErrorName: boolean;
  fieldErrorPhone: boolean;
  openModal: boolean;
  userName: string;
  isUserOptionModalOpen: boolean;
  phoneInputFormat: string;
  phoneErrorMessage: string;
  uploadedAttachments: any;
  userDetails: any;
  headerProfilePic: any;
  showLoader: boolean;
  apiError:boolean;
  sideBarOpen: boolean;
  isSessionExpireModalOpen: boolean;
  sessionExpireMessage:string;
  isSessionExpire:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  addDataContact: any;
  getProfileDetailsApiCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.getProfileDetailsApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      subject: "",
      Detail: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      fieldError: false,
      fieldErrorName: false,
      fieldErrorPhone: false,
      openModal: false,
      userName: "",
      isUserOptionModalOpen: false,
      phoneInputFormat: "",
      phoneErrorMessage: "",
      uploadedAttachments: [],
      userDetails: null,
      headerProfilePic: null,
      showLoader:false,
      apiError:false,
      sideBarOpen:window.innerWidth >= 768,
      isSessionExpireModalOpen:false,
      sessionExpireMessage:"",
      isSessionExpire:false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
      this.getUserProfileDetailsApiCall();
      this.setState({showLoader:true});
      window.addEventListener('resize', this.handlePageResize);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      this.handleContactUsReceiveResponse(apiRequestCallId, responseJson)
      this.handleGetProfileDetailsResponse(apiRequestCallId,responseJson);
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }
    else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfileDetailsApiCall = async() => {
    
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
      "Content-Type": configJSON.contactUsApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setNameInput = (text: string) => {
    this.setState({ name: text, fieldErrorName: false });
  };

  setEmailInput = (text: string) => {
    this.setState({ email: text, fieldError: false });
  };

  setPhoneInput = (text: string, country: any) => {
    this.setState({ phoneNumber: text, fieldErrorPhone: false, phoneInputFormat: country.format, phoneErrorMessage: '' });
  };

  setDetail = (text: string) => {
    this.setState({ Detail: text });
  };

  setSubject = (text: string) => {
    this.setState({ subject: text });
  };

  handleClickOpen = () => {
    this.setState({
      openModal: true,
    });
  };

  handleClose = () => {
    this.setState({openModal: false,name:"",email:"",Detail:"",subject:"",phoneNumber:"", uploadedAttachments: [] });
  };

  handleErrorCheck = () => {
    let isValid = true;
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldErrorName: true });
      isValid =  false;
    }

    if (this.isStringNullOrBlank(this.state.phoneNumber)) {
      this.setState({ fieldErrorPhone: true, phoneErrorMessage: configJSON.phoneNumberError  });
      isValid = false;
    }

    if (this.isStringNullOrBlank(this.state.email)) {
      this.setState({ fieldError: true });
      isValid = false;
    }

    return isValid;
  }

  handleValidPhone= () => {
    const { phoneNumber, phoneInputFormat } = this.state;
    let phoneLengthCount = 0;
    for(let char of phoneInputFormat) {
      if(char ==="."){
        phoneLengthCount++
      }
    }

    if(phoneNumber.length === phoneLengthCount) {
      return true;
    } 
    else {
      return false
    }
  
  }

  isValidAll = () => {
    let isValid = true;
    if(!this.handleErrorCheck()){
      isValid = false
    }
    if (!this.isValidEmail(this.state.email.trim())) {
      this.setState({ fieldError: true });
      isValid = false
    }
    if (!this.handleValidPhone()) {
      this.setState({ fieldErrorPhone: true, phoneErrorMessage: "Please, enter a Valid phone number" });
      isValid = false
    }

    return isValid;
  }

  submit = async () => {
    if(!this.isValidAll()){
      return false;
    } else {
      this.setState({showLoader:true});
      const authToken = await getStorageData("Token")
      const header = {
        token: authToken
      };

      let form = new FormData();
      form.append("[data][name]", this.state.name);
      form.append("[data][email]", this.state.email);
      form.append("[data][details]", this.state.Detail);
      form.append("[data][contact_number]", this.state.phoneNumber);
      form.append("[data][subject]", this.state.subject);

      this.state.uploadedAttachments.forEach((item: any) => {
        return form.append("[data][files][]", item, item.name)
      })

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addDataContact = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addDataUrl
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        form
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }


  handleAttachmentsUpload = (event: any) => {
    let files = Array.from(event.target.files!)
    const uploadedFiles = [...this.state.uploadedAttachments];

    const uniqueFiles = new Set();
    
    files.forEach((item: any) => {
      uploadedFiles.push(item)
    })

    const uniqueFileList = uploadedFiles.filter((file: any) => {
      if (!uniqueFiles.has(file.name)) {
          uniqueFiles.add(file.name);
          return true;
      }
      return false;
    });
    
    this.setState({ uploadedAttachments:  uniqueFileList })
  }

  handleAttachmentsDelete = (deleteFileId: string) => {
    const filteredFiles = this.state.uploadedAttachments.filter((file: any) => file.name!== deleteFileId)

    this.setState({ uploadedAttachments: filteredFiles })
  }
  
  handleBlockPathNavigation = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  handleIsUserOtherOptionModalOpen = () => {
    this.setState({ isUserOptionModalOpen: !this.state.isUserOptionModalOpen })
  }

  handleContactUsReceiveResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.addDataContact) {
      if(responseJson && responseJson.data) {
        this.setState({openModal: true});
      }
      
      if(responseJson.errors){
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.contact) {
          this.setState({ fieldErrorPhone: true, phoneErrorMessage: "Please, enter a Valid phone number" });
        }
        if(error.token){
          this.setState({
            isSessionExpireModalOpen:true,
            isSessionExpire:true,
            sessionExpireMessage: configJSON.sessionExpiredMessage
          });
        }
      })
      }
      this.setState({ showLoader: false })
    } 
  }

  handleGetProfileDetailsResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getProfileDetailsApiCallId ){
      if(responseJson && responseJson.data){
        this.setState({
         ...this.state,
          userDetails:responseJson.data,
          userName: getUserName(responseJson.data)	,
          headerProfilePic: responseJson.data.attributes.avatar,
          apiError:false
        })
      } else if(responseJson.errors){
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.token) {
          this.setState({
            apiError:true,
            isSessionExpireModalOpen:true,
            isSessionExpire:true,
            sessionExpireMessage: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({ showLoader: false })
    }
  }
  handleNavigateToPreviousScreen = () => {
    this.props.navigation.goBack();
  }

  handlePageResize = () => {
    this.setState({ sideBarOpen: window.innerWidth >= 768 });
  }

  handleToggleSideBar = () =>{
    this.setState(prevState=>({
      sideBarOpen:!prevState.sideBarOpen
    }))
  }

  handleBackPathNavigation = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);

    this.send(msg);
  }

  handleIsSessionExpired = (path: string) => {
    this.setState({
      ...this.state,
      isSessionExpireModalOpen: false
    })
    this.handleBackPathNavigation(path)
  }
  // Customizable Area End
}
