import { Box, Link } from '@material-ui/core';
import { getStorageData } from '../../framework/src/Utilities';
import React from 'react'
export const homeicon = require('./home.png');
export const phoneicon = require('./imageContact.png');
export const activeDollaricon = require('./paymentActive.png');

export const activePhoneicon = require('./phone.png');
export const dollaricon = require('./dollar.png');

const handleActiveImg = (path: string) => {
  switch(path){
    case "/Cfnetsuiteintegrationpaymentstatusupdate":
      return { contactUsLogo: phoneicon, paymentLogo: activeDollaricon, paymentTextColor: '#DA291C', contactUsTextColor: '#7D7A78' };
    case "/Contactus":
      return { contactUsLogo: activePhoneicon, paymentLogo: dollaricon, paymentTextColor: '#7D7A78', contactUsTextColor: '#DA291C' };
    default:
      return { contactUsLogo: phoneicon, paymentLogo: dollaricon, paymentTextColor: '#7D7A78' , contactUsTextColor: '#7D7A78' };
  }
}

export function SideBarNavigation({ handleNav }: any) {
  const currentPath = window.location.pathname;
  const { contactUsLogo, paymentLogo, paymentTextColor, contactUsTextColor } = handleActiveImg(currentPath);

  const redirectHome = "cis.edu.sg"
  return  (           
  <Box sx={styles.navbar}>
    <div data-test-id="sideBarId" style={{ padding: '9px 28px' }}>
        <Link href={`https://${redirectHome}`} underline='none' >
        <div 
          data-test-id="cis" 
        //   onClick={() => handleNav(isGoogleLogin ? 'epay.cis.edu.sg/feesportal' : "epay.cis.edu.sg/holidaycamps")} 
          style={styles.logoWrapper}
        >
          <img src={homeicon} style={{ height: '24', width: 24 }} />
          <div style={styles.logoTextStyle}>CIS</div>
        </div>
        </Link>

        <div 
          data-test-id="payment" 
          onClick={() => handleNav("Cfnetsuiteintegrationpaymentstatusupdate")} 
          style={styles.logoWrapper}
        >
          <img style={{ width: 24, height: '24' }} src={paymentLogo} />
          <div 
            style={{ fontSize: 10, fontFamily: 'Inter', textAlign: 'center', color: paymentTextColor, fontWeight: 500 }}
          > 
            payment
          </div>
        </div>

        <div
          data-test-id="contact" 
          onClick={() => handleNav("Contactus")} 
          style={styles.logoWrapper}
        >
          <img style={{ height: '24', width: 24, }} src={contactUsLogo} />
          <div 
            style={{ color: contactUsTextColor, fontSize: 10, textAlign: 'center', fontFamily: 'Inter', fontWeight: 500 }}
          >
            Contact Us
          </div>
        </div>
    </div>
    </Box>
  )
}

const styles = {
  navbar: {
    width: 120,
    minHeight: '100vh',
    padding: '0px 20px',
    justifyContent: 'center',
    borderRight: '1px #ECEBEB solid',
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    marginBottom: '40px', 
    alignItems: 'center', 
    display: 'flex', 
    flexDirection: 'column' as 'column', 
    cursor: "pointer"  
  },
  logoTextStyle: { 
    fontSize: 10, 
    fontFamily: 'Inter', 
    fontWeight: 500, 
    textAlign: 'center' as 'center', 
    color: '#7D7A78' 
  },
}
