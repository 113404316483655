/* eslint-disable quotes */
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import AlertBlock from "../../alert/src/AlertBlock.web";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  imageStatus: any;
  errors: any;
  imageSource: any;
  videoSrc: any;
  photoChanged: boolean;
}

interface SS {
  id: any;
}

export const configJSON = require("./config");

export enum Status {
  SelectOption, //initial state where the user can select upload from pc or upload from webcam
  TakeWebPhoto, //after the user has selected to upload from webcam
  ReviewWebPhoto, //after the user has captured an image using the webcam
  TakeFilePhoto, //after the user has selected to upload from file
  ReviewFilePhoto, //after the user has uploaded an image from file
  NoCameraAccess
}

const globalConfig = require("../../../framework/src/config");

export default class UserProfileAdvanceControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  imageUploadedApiCallId: any;
  imageGetApiCallId: any;
  placeHolder: any;
  authToken: any;
  uniqueSessionRequesterId: any;
  inputElement: any;
  videoRef: any;
  canvasRef: any;
  videoSrc: any;
  streamRef: any;
  alertMessageId: any;

  options = {
    storageOptions: {
      skipBackup: true,
      path: "images"
    }
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.placeHolder = this.placeHolder;

    this.receive = this.receive.bind(this);
    this.placeHolder = require("../assets/placeholder.jpg");
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.UserDeletesProfileImageMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.authToken = "";
    this.state = {
      photoChanged: false,
      imageStatus: Status.SelectOption,
      errors: null,
      imageSource: null,
      videoSrc: null
    };
  }

  getUserPhoto() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.imageGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.photoApiEndPoint
    );

    const header = {
      "Content-Type": "application/json",
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadUserPhoto() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.imageUploadedApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.photoApiEndPoint
    );

    // we do not specify the content-type here on purpose
    // otherwise the upload won't work
    const header = {
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formdata = new FormData();
    if (this.state.imageSource) {
      //this is how we delete the photo when the imageSource is null
      formdata.append("photo", this.state.imageSource);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageUploaded = (message: any, apiCallIdInResponse: string) => {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length) {
      let imageUrl = responseJson.data[0].url
        ? globalConfig.baseURL + responseJson.data[0].url
        : null;
      this.setState({
        imageStatus: Status.SelectOption,
        imageSource: imageUrl,
        photoChanged: false
      });
      if (this.imageUploadedApiCallId === apiCallIdInResponse) {
        if (responseJson.data[0].url) {
          this.showAlert("Updated", "Upload Successful");
        } else {
          this.showAlert("Removal", "Successful Removal");
        }
      }
    } else if (responseJson && responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.showAlert("Session Expired", "Please Log in again.");
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    } else {
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  async receive(from: string, message: Message) {
    const apiCallIdInResponse = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      (this.imageUploadedApiCallId || this.imageGetApiCallId) &&
      (this.imageUploadedApiCallId === apiCallIdInResponse ||
        this.imageGetApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      this.handleImageUploaded(message, apiCallIdInResponse);
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
        this.getUserPhoto();
      }
    }
  }

  onDeleteImagePress() {
    this.setState({
      imageStatus: Status.SelectOption,
      imageSource: null,
      photoChanged: true
    });
  }

  showAlertToDelete() {
    var userValue = confirm("Are you sure you want to delete this image?");
    if (userValue) {
      this.onDeleteImagePress();
    } else {
      return;
    }
  }

  uploadFromPC = (event: any) => {
    if (event.target.files[0] != null) {
      let imageHandler = new FileReader();
      imageHandler.readAsDataURL(event.target.files[0]);
      imageHandler.onload = _event => {
        this.setState({
          imageSource: imageHandler.result,
          errors: null,
          imageStatus: Status.ReviewFilePhoto,
          photoChanged: true
        });
      };
    }
  };

  checkPermissionAndlaunchCamera() {
    // this.imageStatus = this.status.TakeWebPhoto;
    const mediaDevices = navigator.mediaDevices;
    if (mediaDevices && mediaDevices.getUserMedia) {
      mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          this.streamRef = stream;
          this.setState({ imageStatus: Status.TakeWebPhoto, videoSrc: stream });
        })
        .catch(error => {
          runEngine.debugLog("checkPermissionAndlaunchCamera", error);
          this.setState({ imageStatus: Status.NoCameraAccess });
        });
    } else {
      this.setState({ imageStatus: Status.NoCameraAccess });
    }
  }

  captureFromWebCam = () => {
    const dw = this.canvasRef.width; // destination width
    const dh = this.canvasRef.height; // destination height
    const context = this.canvasRef
      .getContext("2d")
      .drawImage(this.videoRef, 0, 0, dw, dh);
    const imageSrc = this.canvasRef.toDataURL("image/png");
    this.streamRef.getTracks().forEach(function(track: any) {
      track.stop();
    });
    this.setState({
      imageSource: imageSrc,
      errors: null,
      imageStatus: Status.ReviewWebPhoto,
      photoChanged: true
    });
  };

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }
}
// Customizable Area End