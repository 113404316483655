import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  step: string,
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  otpValue: any;
  hasErrorEmail: boolean;
  hasErrorOtp: boolean;
  time: number,
  emailError: string;
  emailToken:string;
  isResendOtp: boolean;
  showLoader:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  interval: any = null;
  submitEmailApiCallId: string = "";
  otpConfirmationLoginApiCallId: string = "";
  resendOtpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      step: 'email',
      otpValue: "",
      hasErrorEmail: false,
      hasErrorOtp: false,
      email: "",
      password: "",
      enablePasswordField: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      time: 60,
      emailError: "",
      emailToken:"",
      isResendOtp: false,
      showLoader:false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if (this.state.step === 'otp') {
      this.interval = setInterval(() => {
        this.setState(prevState => ({
          time: prevState.time > 0 ? prevState.time - 1 : 0
        }));
      }, 1000);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount(): Promise<void> {
    clearInterval(this.interval as any);
  }

  handleShowResendOtp = () => {
    if(!this.state.isResendOtp) {
      this.setState({
        ...this.state,
        isResendOtp: true,
      })
    } 
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
  
    if (this.state.step === 'otp' && (prevState.step !== 'otp'|| prevState.isResendOtp === true)) {
      this.interval = setInterval(() => {
        this.setState(prevState => ({
          time: prevState.time > 0 ? prevState.time - 1 : 0
        }));
      }, 1000);
    } else if (this.state.step !== 'otp' && prevState.step === 'otp') {
      clearInterval(this.interval as any);
    }

    if (this.state.time === 0) {
      clearInterval(this.interval as any);
      this.handleShowResendOtp();
    }
  }

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    handleNavigate = () => {
      this.props.navigation.navigate("UserProfileBasicBlock")
    }
    handleStepChange = (stepVal: string) => {
    this.setState({ step: stepVal })
    }

    isValidEmail = (Email: string) => {
      let reg = configJSON.emailValidRegex;
      if (reg.test(Email) === false) {
        return false;
      } else {
        return true;
      }
    };

    handleEmailChange = (event: any) => {
      this.setEmail(event.target.value)
      this.setState({ hasErrorEmail: false })
    }

    submitEmailApi = () => {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      const submitEmail = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.submitEmailApiCallId = submitEmail.messageId;
  
      submitEmail.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailVerificationLoginApiEndPoint
      );
  
      submitEmail.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      submitEmail.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({  email: this.state.email })
      );

      submitEmail.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(submitEmail.id, submitEmail);
    }

    handleEmailSubmit = () => {
      if(this.isValidEmail(this.state.email)) {
        this.submitEmailApi()
        this.setState({ showLoader: true })
      } else {
        this.setState({ hasErrorEmail: true, emailError: "Please enter valid Email address." })
        this.setState({showLoader: false})
      }
    }

    handleEmailLoginCallIdRecieveResponse = (apiRequestCallId: string,responseJson: any, errorReponse: any) => {
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({showLoader:false});
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.saveLoggedInUserData(responseJson);
          this.sendLoginSuccessMessage();
          this.openInfoPage();
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.sendLoginFailMessage();
          this.setState({showLoader:false});
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }


    handleSubmitEmailApiCallIdRecieveResponse = (apiRequestCallId: string, responseJson: any) => {
      if (apiRequestCallId === this.submitEmailApiCallId) {
        if (responseJson.meta && responseJson.meta.token) {
            setStorageData("Token", responseJson.meta.token)
            setStorageData("RefreshToken", responseJson.meta.token)
            this.setState({
              ...this.state,
              emailToken: responseJson.meta.token,
              isResendOtp: false,
              time: 60
            })
            this.handleStepChange("otp")
        } else if(responseJson.status === "fail" && responseJson.msg === "Customer does not exist") {
            this.setState({ hasErrorEmail: true, emailError: responseJson.msg});
            
        } else if(responseJson.errors){
          this.setState({ hasErrorEmail: true, emailError: responseJson.errors });
        }
        this.setState({ showLoader: false});
    }
  }

    handleValidationApiCallIdRecieveResponse = (apiRequestCallId: string, responseJson: any ) => {
      if (
        apiRequestCallId === this.validationApiCallId &&
        responseJson !== undefined
      ) {
        const arrayholder = responseJson.data;

        if (arrayholder && arrayholder.length !== 0) {
          let regexData = arrayholder[0];

          if (regexData && regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
          }
        }
      }
    }


  
    handleOtpSubmit = () => {
    if(this.state.isResendOtp){
      this.resendOtpApiCall()
      this.setState({ showLoader: true })
    } else if(this.state.otpValue !== ""){
      this.getOtpVerifyApiCall()
      this.setState({ showLoader: true })
    }
  }

  resendOtpApiCall = () => {
    this.setState({ hasErrorOtp: false, otpValue: "" })

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.emailToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    const body = {
        "email": this.state.email
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOtpVerifyApiCall = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.emailToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpConfirmationLoginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyLoginOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    const body = {
        "otp": this.state.otpValue
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigationByPath = (path: string) => {
    const navigationPathMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationPathMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    navigationPathMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(navigationPathMsg);
  }

  handleBackBtn = () => {
    this.setState({
      ...this.state,
      otpValue: "",
      emailToken: "",
      hasErrorOtp: false,
      isResendOtp: false, 
    })
    this.handleStepChange("email")
  }

  handleLoginSuccess = (responseJson: any, isGooleLogin: boolean) => {
    setStorageData("UserData", JSON.stringify(responseJson.data))
    setStorageData("Token", responseJson.meta.token)
    setStorageData("RefreshToken", responseJson.meta.refresh_token)
    setStorageData("GoogleLogin", `${isGooleLogin}`)

    this.handleNavigationByPath("Cfnetsuiteintegrationpaymentstatusupdate")
  }

  handleOtpVerifyCallIdResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.otpConfirmationLoginApiCallId) {
      if (responseJson.data && responseJson.meta) {
          this.setState({showLoader:false})
          this.handleLoginSuccess(responseJson, false)
      } else if(responseJson.errors) {
        this.setState({
          ...this.state,
          hasErrorOtp: true,
          showLoader:false
        })
      }
    }
  }

  handleResendOtpCallIdResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.resendOtpApiCallId) {
      if(responseJson.data && responseJson.meta.token){
        setStorageData("Token", responseJson.meta.token)
        setStorageData("RefreshToken", responseJson.meta.token)
        this.setState({
          ...this.state,
          emailToken: responseJson.meta.token,
          isResendOtp: false,
          time: 60,
          showLoader: false
        })
      } else if(responseJson.errors){
        this.setState({
          ...this.state,
          isResendOtp: true,
          showLoader:false
        })
      }
    }
  }
  

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        this.handleValidationApiCallIdRecieveResponse(apiRequestCallId, responseJson)

        this.handleEmailLoginCallIdRecieveResponse(apiRequestCallId, responseJson, errorReponse)

        this.handleSubmitEmailApiCallIdRecieveResponse(apiRequestCallId, responseJson)

        this.handleOtpVerifyCallIdResponse(apiRequestCallId, responseJson)

        this.handleResendOtpCallIdResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
