// Customizable Area Start
import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import invert from 'invert-color';

/* get colors */
export const colors = (idDark=false) => {
  const lightColors:any = {
    background: '#FFFFFF',
    primary: '#512DA8',
    text: '#121212',
    error: '#D32F2F',
    black: '#000',
    white: '#fff',
  }

  const darkColors:any = {}
  for (const colors in lightColors) {
    darkColors[colors] = invert(lightColors[colors]);
  }
  return idDark ? darkColors : lightColors;
}

export const getUserName = (userData: any) => {
    if(userData?.attributes?.full_name) {
      return userData?.attributes?.full_name
    } else if((userData?.attributes?.first_name !== null) ||  (userData?.attributes?.last_name !== null)){
      return `${userData?.attributes?.first_name} ${userData?.attributes?.last_name}`
    } else {
      return "-- --"
    }
}

export const BootstrapInputStyle = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    '& input': {
      borderColor: '#E67067',
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E0DE',
    padding: '20px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },

}))(InputBase);
// Customizable Area End