import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController from "./GoogleWebController";
import { GoogleDelegate } from "./GoogleDelegate";

const configJSON = require("./config");

// Customizable Area Start
interface ResponseJson {
  meta: {
    token: string;
  };
}

import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isRegistration: boolean;
  classes: any;
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  ssoLoginError: boolean;
  showLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SocialMediaAccountController
  extends BlockComponent<Props, S, SS>
  implements GoogleDelegate {
  createAccountAPICallId: string = "";
  googleUser: object;
  // Customizable Area Start
  getGoogleSsoLoginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      loading: false,
      ssoLoginError: false,
      showLoader: false
      // Customizable Area End
    };
  }

  // Customizable Area Start
  googleUserStatusChanged(userInfo: {
    email: string;
    id: string;
    status?: string;
  }): void {
    console.log(userInfo);
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  openErrorModal = () => {
    this.setState({ 
      ...this.state, 
      ssoLoginError: true 
    });
  }

  closeErrorModal = () => {
    this.setState({ 
      ...this.state, 
      ssoLoginError: false 
    });
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.stopLoading();

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
       this.handleGoogleLoginRecieveResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleGoogleLoginRecieveResponse = (apiRequestCallId: string, responseJson: any) => {
    if(apiRequestCallId === this.getGoogleSsoLoginApiCallId && responseJson) {
      if(responseJson.data && responseJson.meta){
          this.setState({showLoader:false});
          setStorageData("UserData", JSON.stringify(responseJson.data))
          setStorageData("Token", responseJson.meta.token)
          setStorageData("RefreshToken", responseJson.meta.refresh_token)
          setStorageData("GoogleLogin", "true")

          this.handleSsoScreenPathNavigation("Cfnetsuiteintegrationpaymentstatusupdate")
      } else if(responseJson.errors && Array.isArray(responseJson.errors)){
          this.openErrorModal()
          this.setState({showLoader:false});
      }

    }
  }

  handleSsoScreenPathNavigation = (path: string) => {
    const navigationPathMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationPathMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    navigationPathMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(navigationPathMsg);
  }

  handleSsoSuccess = (response: any) => {
    this.setState({showLoader:true})
    this.startLoading()
    response && response.credential && this.getGoogleSsoLoginApiCall(response.credential)
  }

  handleSsoError = () => {
      console.log('on error sso')
  }


  getGoogleSsoLoginApiCall = (userEncodedCred: string) => {
    const headers = {
        "Content-Type": configJSON.urlHeaderTypeJSON,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGoogleSsoLoginApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.googleSsoLoginPostEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postHttpRequest
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
            credentials: userEncodedCred
        })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
