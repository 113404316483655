import * as React from "react";
import { Image, View, Text, StyleSheet, Platform } from "react-native";

import UserProfileAdvanceController, {
  Props,
  Status
} from "./UserProfileAdvanceControllerWeb";

export default class UserProfileAdvance extends UserProfileAdvanceController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.requestSessionData();
  }

  render() {
    const { imageStatus, imageSource, videoSrc } = this.state;

    return (
      <View style={styles.container}>
        <Text style={styles.userProfileText}>User profile</Text>

        <Text>
          This is your profile, Here you can see and update your personal
          information.
        </Text>

        <View style={{ flexDirection: "row" }}>
          <View>
            {imageStatus !== Status.TakeWebPhoto && (
              <Image
                style={
                  imageStatus === Status.SelectOption ||
                  imageStatus === Status.ReviewFilePhoto ||
                  imageStatus === Status.NoCameraAccess
                    ? styles.image
                    : styles.capturedImage
                }
                resizeMode={"cover"} /* <= changed  */
                source={imageSource || this.placeHolder}
              />
            )}

            {imageStatus === Status.TakeWebPhoto && (
              <video
                autoPlay
                id="video"
                ref={vid => {
                  if (vid) {
                    vid!.srcObject = videoSrc;
                    this.videoRef = vid;
                  }
                }}
                style={{ width: 200, height: 180 }}
              />
            )}
            <canvas
              id="canvas"
              style={{ display: "none", width: 500, height: 500 }}
              ref={canvas => (this.canvasRef = canvas)}
            />
          </View>

          <View style={{ marginLeft: 10 }}>
            {(imageStatus === Status.SelectOption ||
              imageStatus === Status.NoCameraAccess) && (
              <View>
                <input
                  id="fileInput"
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={this.uploadFromPC}
                  ref={input => (this.inputElement = input)}
                />
                <Text
                  style={styles.bgImageChooseFrom}
                  onPress={() => this.inputElement.click()}
                >
                  UPLOAD FROM COMPUTER
                </Text>
              </View>
            )}

            {imageStatus === Status.SelectOption && (
              <Text
                testID={"checkPermissionAndLaunchGallery"}
                style={styles.bgImageChooseFrom}
                onPress={() => this.checkPermissionAndlaunchCamera()}
              >
                UPLOAD FROM WEBCAM
              </Text>
            )}

            {imageStatus === Status.TakeWebPhoto && (
              <Text
                testID={"captureFromWebCam"}
                style={styles.bgImageChooseFrom}
                onPress={() => this.captureFromWebCam()}
              >
                TAKE PICTURE
              </Text>
            )}

            {imageStatus === Status.ReviewWebPhoto && (
              <Text
                testID={"checkPermissionAndlaunchCamera"}
                style={styles.bgImageChooseFrom}
                onPress={() => this.checkPermissionAndlaunchCamera()}
              >
                RETAKE
              </Text>
            )}

            {(imageStatus === Status.SelectOption ||
              imageStatus === Status.ReviewFilePhoto ||
              imageStatus === Status.ReviewWebPhoto) &&
              imageSource && (
                <Text
                  testID={"ensureUserActionForDeletingImage"}
                  style={styles.bgImageChooseFrom}
                  onPress={() => this.showAlertToDelete()}
                >
                  DELETE IMAGE
                </Text>
              )}
          </View>
        </View>
        {imageStatus === Status.NoCameraAccess && (
          <Text>No Access to webcam. Please enable access and try again.</Text>
        )}

        {this.state.photoChanged && (
          <Text
            testID={"uploadUserPhoto"}
            style={styles.bgSave} 
            onPress={() => this.uploadUserPhoto()}>
            SAVE CHANGES
          </Text>
        )}
      </View>
    );
  }
}

// const win = Dimensions.get("window");
const imageWidth: any = 200;

const styles = StyleSheet.create({
  userProfileText: {
    color: "rgb(98, 0, 238)",
    fontFamily: ".AppleSystemUIFont",
    fontSize: 30,
    fontStyle: "normal",
    fontWeight: "normal",
    backgroundColor: "transparent",
    marginTop: 16,
    marginBottom: 20
  },
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },

  bgImageChooseFrom: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "center",
    backgroundColor: "#00000000",
    marginTop: 16,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    color: "#6200EE"
  },
  bgSave: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "center",
    backgroundColor: "#6200EE",
    borderColor: "#6200EE",

    marginTop: 16,
    borderWidth: 1,
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    color: "#fff",
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  },
  image: {
    flex: 1,
    // alignSelf: 'stretch',
    width: imageWidth,
    height: imageWidth,
    marginTop: 16,
    backgroundColor: "#767676"
  },

  capturedImage: {
    marginTop: 16,
    width: 300,
    height: 150
  },

  video: {
    width: 200,
    height: 200
  },

  canvas: {
    display: "none",
    width: 500,
    height: 500
  }
});
