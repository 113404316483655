export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const homeicon = require('../assets/home.png');
export const phoneicon = require('../assets/imageContact.png');
export const dollaricon = require('../assets/paymentActive.png');
export const closeicon = require('../assets/image_close.png');
export const arrowdown = require('../assets/arrowdown.png');
export const arrowback = require('../assets/arrowback.png');
export const download = require('../assets/download.png');
export const loader = require('../assets/loader.png');
export const student = require('../assets/student.png');
export const dollarBlueIcon = require('../assets/dollarblue.png');
export const searchIcon = require('../assets/searchIcon.png');
export const paymentOptions = require('../assets/paymentOptions.pdf');
export const hamburgerIcon = require('../assets/hamburger.png')
