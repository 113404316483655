import React from "react";
// Customizable Area Start
import {
  Box, 
  IconButton, 
  Modal,
  Typography, 
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
import { GoogleLogin, GoogleOAuthProvider  } from "@react-oauth/google";

import CloseIcon from '@material-ui/icons/Close';
import { imgLogo, imgLogoRound, imgLogoText } from './assets';
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

const configJSON = require("./config.js");

import SocialMediaAccountController, {
  Props
} from "./SocialMediaAccountController.web";

class SocialMediaAccount extends SocialMediaAccountController {
  static SocialMediaAccount: SocialMediaAccount;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={`${classes.mainContainer}`}>
          <Box className={classes.bodyLeftContainer}>
            <Box className="body">
              <Box className="header" style={{ marginBottom: '40px' }}>
                <img src={imgLogo} />
                <img style={{ marginLeft: '10px' }} src={imgLogoText} />
              </Box>

                <GoogleOAuthProvider clientId={configJSON.clientID}>
                  <Box className={classes.googleButtonTextStyle} data-testid="google-login-btn-Id">
                    <GoogleLogin
                      data-testid="google-login-btn"
                      onSuccess={this.handleSsoSuccess}
                      onError={this.handleSsoError}
                      useOneTap={true}
                    />
                  </Box>
                </GoogleOAuthProvider>
            </Box>
            {this.state.showLoader && <Loader loading={true} />}
            <Box className="footer">
              <div style={{ width: '70%', wordBreak: 'break-word', fontSize: '12px', fontWeight: 400 }}>
                {configJSON.footerHelperText} 
                <span
                  data-testid="click-here-txt-testId" 
                  style={{ color: '#DA291C', cursor: "pointer" }} 
                  onClick={() => this.handleSsoScreenPathNavigation("EmailAccountLoginBlock")}
                > 
                  click here.
                </span> 
              </div>
            </Box>
          </Box>
          <Box className={classes.bodyRightLogoContainer}>
            <img src={imgLogoRound} />
            <div className={classes.footerOwnTheFutureTxtStyl}>{configJSON.ownTheFutureText}</div>
          </Box>

          
          <Modal 
              open={this.state.ssoLoginError} 
              data-testid="ssoLoginModalId" 
              onClose={this.closeErrorModal}
          >
              <Box className={classes.modalStyle}>
                <IconButton 
                    aria-label="Close" 
                    data-testid="ssoLoginModalIconCloseId" 
                    className={classes.modalCloseBtnStyl} 
                    onClick={this.closeErrorModal}
                >
                    <CloseIcon/>
                </IconButton>
                <div style={{ textAlign: "center", width: "100%" }}>
                    <Typography style={{ fontSize: "20px", fontWeight: 500, color: "black" }}> Error </Typography>
                    <div style={{marginTop:"20px", fontSize: "14px", fontWeight: 400, color: "black"}}>{configJSON.ssoLoginErrorMsg}</div>
                </div>
              </Box>
          </Modal>
          
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ssoLoginComponentStyle = createStyles({
  mainContainer: {
    display: 'grid',
    minWidth: '100vw',
    minHeight: '100vh',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

    '& *': {
      fontFamily: "'Inter', sans-serif",
      boxSizing: 'border-box'
    },

    '@media (max-width: 992px)': {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    }
  },

  error: {
    '& input': {
      borderColor: 'red !important'
    }
  },

  bodyLeftContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    "& .header": {
      marginLeft: 20,
      marginTop: 20,
    },

    "& .body": {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      height: 'calc(100vh - 70px)',
      paddingTop: "100px",

      '& .form': {
        width: '50%',
        padding: 40,
        '@media (max-width: 680px)': {
          width: '80%'
        }
      }
    },

    '& .footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },

  bodyRightLogoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#DA291C',
    borderRadius: '0 0 0 60px',
    '@media (max-width: 992px)': {
      display: 'none'
    }
  },

  OTPContainer: {
    width: '50%',
    '@media (max-width: 680px)': {
      width: '80%'
    }
  },

  customOTP: {
    '& input': {
      width: '20% !important',
      aspectRatio: '1/1',
      borderRadius: '8px',
      border: '1px #E0E0DE solid',
      marginRight: 20,
      fontSize: '16px'
    },
    "& input:last-child": {
      marginRight: 0
    }
  },

  googleButtonTextStyle: {
    cursor: 'pointer',
    paddingTop: "70px",
    paddingLeft: 6,
    paddingRight: 3,
    fontSize: 14,
    color: "#2553b4",
    fontFamily: "Helvetica-Bold, sans-serif",
    marginLeft: 8
  },

  footerOwnTheFutureTxtStyl: { 
    marginTop: '53px', 
    color: 'white', 
    fontSize: 36, 
    fontFamily: 'Inter', 
    fontWeight: 700 
  },

  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: 'background.paper',
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    outline: 'none',
    borderBottomRightRadius: 20,
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Inter', sans-serif",
    backgroundColor: "white !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  modalCloseBtnStyl: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: 'red',
  }
})

export const SocialMediaAccountBlock = withStyles(ssoLoginComponentStyle)(SocialMediaAccount)

// Customizable Area End

export default SocialMediaAccount;
