export const homeicon = require('../assets/home.png');
export const phoneicon = require('../assets/phone.png');
export const dollaricon = require('../assets/dollar.png');
export const studentimg = require('../assets/student.png');
export const closeicon = require('../assets/image_close.png');
export const arrowdown = require('../assets/arrowdown.png');
export const arrowback = require('../assets/arrowback.png');
export const check = require('../assets/check.png');
export const attachFile = require('../assets/attachment.png');
export const contactUsStudentsOriginalImage = require('../assets/contact_us_student_original.jpeg');
export const contactUsStudentsImage = require('../assets/contact_us_student_cropped.jpeg');
export const hamburgerIcon = require('../assets/hamburger.png')
