// Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    InputBase,
} from "@material-ui/core";
import { createTheme, ThemeProvider, createStyles, withStyles, styled } from "@material-ui/core/styles";
import { arrowback, studentimg, arrowdown, baseline, cameraImage, hamburgerIcon } from './assets'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css';

// Customizable Area End
// Customizable Area Start
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import { SideBarNavigation } from "../../../components/src/SideNavbar.web";
import ProfileModal from "../../../components/src/ProfileModal.web";
import Loader from "../../../components/src/Loader.web";
import StatusNotifyModal from "../../../components/src/StatusNotifyModal.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const BootstrapStyleInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        borderRadius: 8,
        border: '1px solid #E0E0DE',
        padding: '20px 8px',
        backgroundColor: theme.palette.common.white,
        fontSize: 16,
        width: '100%',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: "#949492",
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            // borderColor: theme.palette.primary.main,
        },
    },
    error: {
        '& input': {
            borderColor: 'red !important',
        },
    },

}))(InputBase);

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    renderUserModalOption = () => {
        return (
            <ProfileModal
                isProfileModalOpen={this.state.isUserOptionModalOpen}
                handleUserModalOptionOpen={this.handleIsUserOptionModalOpen}
                handleNavigationPath={(path: string) => this.handleNavigationPath(path)}
            />
        )
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { userData, userHeaderProfilePic } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start 
            <ThemeProvider theme={theme}>
                <Container maxWidth="md">
                    <MainContainer>
                    <Box className="container">
                        {this.state.sideBarOpen && <SideBarNavigation handleNav={this.handleNavigationPath} />}
                        <Box className={classes.content}>
                            <div className="payment-header">
                                <div className="payment-header-icon">
                                    <img data-test-id="sideBarToogleId" onClick={()=>this.handleSideBar()} src={hamburgerIcon} alt="hamburgerIcon" style={{ width: "20px", height: "20px"}}/>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img data-test-id="backArrowImgId" onClick={() => this.handleBackNavigation()} style={{ width: '24px', height: '24px', cursor:'pointer' }} src={arrowback} />
                                    <div style={{ marginLeft: '5px', color: '#3D3935', fontSize: 24, fontFamily: 'Inter', fontWeight: 700 }}>Profile</div>
                                </div>
                                <div
                                    data-test-id="userModalOptionId"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer', position: "relative" }}
                                    onClick={() => this.handleIsUserOptionModalOpen()}
                                >
                                   <img 
                                        className={classes.userHeaderProfileImgStyle}
                                        src={userHeaderProfilePic ?? studentimg} 
                                    />
                                    <div
                                        style={{
                                            marginLeft: '5px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700,
                                            margin: "0px 8px", maxWidth: "150px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                        }}
                                    >
                                        Hi, {this.state.userName}
                                    </div>
                                    <img style={{ width: '20px', height: '20px' }} src={arrowdown} />
                                </div>
                            </div>

                            {this.renderUserModalOption()}
                            {this.state.showLoader && <Loader loading={true}/>}

                            <Box className={classes.mainBox}>
                                <div style={{ width: '100%', height: '22%', borderRadius: '5px 5px 45px 0', backgroundColor: "#da291c", marginBottom: "30px" }} />
                                <div className={classes.editProfileWrapperMain}>
                                    <div className="editProfileWrapperImg">
                                        {this.state.userImg ?
                                            <img
                                                src={this.state.userImg}
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                            :
                                            <span className={classes.initialsProfileImgStyl}>
                                                {this.getInitials()}
                                            </span>
                                        }
                                    </div>
                                    <div className="editProfileWrapper">
                                        <label htmlFor="uploadImage" className={classes.editProfileLabelStyle}>
                                            <input
                                                type="file"
                                                id="uploadImage"
                                                accept="images/*"
                                                style={{ display: 'none' }}
                                                data-test-id="upload-profile-testId"
                                                onChange={(event) => this.updateProfileImage(event)}
                                            />
                                            <img src={cameraImage} alt="cameraImage" style={{ position: 'relative', width: '20px', height: '18px' }} />
                                            <span style={{ fontSize: '12px', color: 'white', fontWeight: 600, marginTop: "8px" }}>Edit Picture</span>
                                        </label>
                                    </div>
                                </div>
                                    {this.state.showImgError && <div style={{fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "3px"}}>
                                    {this.state.profileUpdateStatus}</div>}
                                <FormContainer>
                                    
                                <div className="formBox">
                                    <div className={classes.body}>
                                        <div className="w-full-form" style={{ paddingLeft: 50, marginTop: '28px', width: '280px', color: '#7D7A78', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>Personal details</div>

                                        <div className={classes.form}>
                                            <div style={{ color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}> Full Name</div>
                                            <BootstrapStyleInput fullWidth placeholder="Enter your name" value={this.state.userName} readOnly={true} />
                                            <div style={{ marginTop: '16px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Email Address</div>
                                            <BootstrapStyleInput fullWidth placeholder="Enter your email address" value={userData?.attributes?.email} readOnly={true} />
                                            <div style={{ marginTop: '16px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Contact Number</div>
                                            <div className={classes.customPhoneInput}>
                                                <PhoneInput country={'sg'} value={userData?.attributes?.full_phone_number} disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ borderTop: '1px #ECEBEB solid' }}></div>
                                    {/* structure */}
                                    <div className={classes.body}>
                                        <div className="w-full-form" style={{ paddingLeft: 50, marginTop: '28px', width: '280px', color: '#7D7A78', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>Student Profile(s)</div>

                                        <div className={classes.form}>
                                            <div style={{ display: 'flex', marginBottom: '40px', flexWrap: "wrap", width: "100%", paddingLeft: "20px", gap: "20px 0" }}>
                                                {this.state.childDetails.length > 0 &&
                                                    this.state.childDetails.map((item: any) =>
                                                        <div
                                                            key={item.attributes.id}
                                                            style={{
                                                                borderRadius: '10px', border: '3px #ECEBEB solid',
                                                                textAlign: 'start', display: 'flex', alignItems: 'center',
                                                                justifyContent: 'center', padding: '15px', marginRight: '20px',
                                                                backgroundColor: 'lightgray',
                                                            }}
                                                        >
                                                            <img src={baseline} style={{ width: '30px', height: '30px' }} />
                                                            <div
                                                                style={{
                                                                    color: '#3D3935', marginLeft: '5px', fontSize: 14,
                                                                    fontFamily: 'Inter', fontWeight: 700,
                                                                }}
                                                            >
                                                                {item.attributes.child_firstname} {item.attributes.child_lastname}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderTop: '1px #ECEBEB solid' }} />

                                    <div id="profile_footer_btns" style={{ display: 'flex', width: "100%", backgroundColor: "white", marginBottom: "55px" }}>
                                        <div className="footer-bottom" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: "auto", paddingBottom: "40px" }}>
                                            <div
                                                data-test-id="backBtnId"
                                                onClick={() => this.handleCancelProfilePicture()}
                                                style={{
                                                    marginTop: '32px', cursor: 'pointer', paddingLeft: 16, paddingRight: 16,
                                                    paddingTop: 10, paddingBottom: 10, background: 'antiquewhite', borderRadius: 8,
                                                    overflow: 'hidden', justifyContent: 'flex-end', alignItems: 'center', gap: 8,
                                                    display: 'inline-flex', marginRight: '20px'
                                                }}
                                            >
                                                <div data-test-id={"txtBackBtn"} style={{ color: '#DA291C', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>Cancel</div>
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: '32px', cursor: 'pointer', paddingLeft: 16,
                                                    paddingRight: 16, paddingTop: 10, paddingBottom: 10, background: '#DA291C',
                                                    borderRadius: 8, overflow: 'hidden', justifyContent: 'flex-end', alignItems: 'center',
                                                    gap: 8, display: 'inline-flex'
                                                }}
                                                data-test-id={"updateImgId"}
                                                onClick={this.updateUserProfileImgApiCall}
                                            >
                                                <div style={{ color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }}>
                                                    Save Changes
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                </FormContainer>
                            </Box>
                        </Box>
                    </Box>
                    </MainContainer>
                    <StatusNotifyModal 
                        isModalOpen={this.state.isProfileUpdateStatusModal}
                        handleModalClose={this.handleCloseSuccessModal}
                        modalMessage={this.state.profileUpdateStatus}
                        isSessionExpired={this.state.isProfileDetailsFetch}
                        handleNavigationPath={(path: string) => this.handleNavigationPath(path)}
                    />
                </Container>
            </ThemeProvider>
            // Customizable End Start
            //Merge Engine End DefaultContainer
        );
    }
}


// Customizable Area End

// Customizable Area Start

const webStyleNew = createStyles({
    "MuiContainer-root": {
        padding: '0 !important'
    },
    "MuiContainer-maxWidthMd": {
        maxWidth: '100%'
    },


    form: {
        padding: '28px 47px',
        width: 'calc(100% - 280px)',
        "@media(max-width: 767px)": {
            width: 'calc(100% - 0px)',
            padding: '0 120px 0px 19px',
        }
    },
    form1: {
        padding: '28px 47px',
    },
    navbar: {
        width: 120,
        height: '100vh',
        padding: '0px 20px',
        justifyContent: 'center',
        borderRight: '1px #ECEBEB solid',
        display: 'flex',
        alignItems: 'center',
    },
    userHeaderProfileImgStyle: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: '3px #ECEBEB solid'
    },
    body: {
        display: 'flex',
        backgroundColor: "white",
        "@media(max-width: 767px)": {
            flexDirection: 'column',   
        },
    },
    content: {
        flex: '1',
        overflowY: 'auto',
        backgroundColor: "aliceblue",
        height: "100vh",
        overflowX: 'hidden'
    },
    mainBox: {
        width: "97%",
        height: "100%",
        margin: "20px",
        backgroundColor: "white"
    },

    customPhoneInput: {
        width: '100%',
        '& .form-control': {
            position: 'relative',
            borderRadius: 8,
            border: '1px solid #E0E0DE',
            backgroundColor: theme.palette.common.white,
            fontSize: 16,
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            color: "#949492",
            // Use the system font instead of the default Roboto font.
            ' &:focus': {
                // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            },
        }
    },

    editProfileWrapper: {
        position: 'absolute',
        zIndex: 10,
    },
    editProfileWrapperMain: {
        marginTop: -80,
        marginLeft: 30,
        display: 'flex',
        position: 'relative',
        width: "100px",
        '& .editProfileWrapper': {
            position: 'absolute',
            zIndex: 10,
            display: 'none',
        },
        '&:hover .editProfileWrapper': {
            display: 'block',
        },
        '& .editProfileWrapperImg': {
            position: 'relative',
            borderRadius: '50%',
            border: '3px #ECEBEB solid',
            '&:before': {
                width: '100px',
                height: '100px',
                content: '""',
                position: 'absolute',
                left: '0',
                top: '0',
                backgroundColor: 'rgba(0,0,0,0.0)',
                borderRadius: '50%',
            },
            '&:hover:before': {
                backgroundColor: 'rgba(0,0,0,0.7)',
            }

        },
        '&:hover .editProfileWrapperImg:before': {
            backgroundColor: 'rgba(0,0,0,0.7)',
        },
    },


    editProfileLabelStyle: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        marginTop: "28px",
        marginLeft: "20px",
        cursor:'pointer'
    },

    profileSuccessModalWrapper: {
        '& div:first-child': {
            // backgroundColor: 'rgba(255, 255, 255, 0.90) !important'
        }
    },

    modalCloseButton: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        color: 'red',
    },
    logoutBtnStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: '#FFFFFF',
        padding: "10px 16px",
        backgroundColor: '#DA291C'
    },
    initialsProfileImgStyl: {
        fontWeight: 600,
        height: "100px",
        width: "100px",
        display: "flex",
        backgroundColor: "#fbeae8",
        borderRadius: "50%",
        marginTop: "0px",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "48px",
        color: "#DA291C",
    },
})

const FormContainer = styled(Box)({
    backgroundColor: '#fff', 
    width: '100%',
    '& .formBox':{
        width: "85%", 
        margin: "0 auto", 
        marginLeft: "100px",
        "@media(max-width: 767px)": {
            width: "100%",
            marginLeft: "0",
            '& .w-full-form': {
                width: "100% !important", 
                marginBottom:"15px"
            },
            '& .footer-bottom': {
                justifyContent: 'flex-start !important',
                paddingLeft: '30px'
            }
        },
    }
})

const MainContainer = styled(Box)({
    width: '100%',
    "& .container": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      '& *': {
          fontFamily: "'Inter', sans-serif",
          boxSizing: 'border-box'
      },
      "@media(max-width: 992px)": {
        position: 'unset'
      }
    },
    "& .payment-header": {
      flexWrap: 'wrap',
      position: 'relative',
      padding: '24px 40px 24px 0px',
      paddingLeft: '48px',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: "aliceblue",
      "@media(min-width: 768px)": {
        '& .payment-header-icon': {
          display: 'none',
        }
      },
      "@media(max-width: 767px)": {
        gap: '10px',
        '& .payment-header-icon': {
          display: 'flex',
          position: 'absolute',
          left: '13px',
          top: '33px',
          cursor: 'pointer'
        }
      },
      "@media(max-width: 500px)": {
        padding: '24px 40px 24px 40px',
        cursor: 'pointer'
      }
    },
  })

export const UserProfileBlock = withStyles(webStyleNew)(UserProfileBasicBlock)

// Customizable Area End
