import { Box, Modal, styled } from '@material-ui/core'
import React from 'react'

const userOptionsModalStyle = {
    userOptionModalProfileWrapper: {
        height: "85px",
        width: "114x",
        padding: "6px 5px",
        border: "1px solid #ecebeb",
        display: "flex",
        borderRadius: "8px",
        alignItems: "center",
        flexDirection: "column" as "column",
        position: "absolute" as "absolute",
        justifyContent: "space-evenly",
        top: "75px",
        backgroundColor: "white",
        zIndex: 999,
        right: "43px",
        outline:"none"
    },
    userBasicProfileOptionStyle: {
        width: "140px",
        backgroundColor: "#FBEAE8 !important",
        borderRadius: "8px 8px 0px 0px",
        height: "35px",
        gap: "8px",
        padding: "12px 16px",
        fontSize: "12px",
        display: "flex",
        cursor: "pointer",
        color: "#DA291c",
    },
    userLogoutOptionStyle: {
        backgroundColor: "white",
        height: "35px",
        width: "140px",
        gap: "8px",
        display: "flex",
        padding: "12px 16px",
        fontSize: "12px",
        cursor: "pointer",
        borderRadius: "0px 0px 4px 4px",
    },
}
const CustomModal = styled(Modal)({

    '& div:first-child': {
      backgroundColor: 'transparent !important'
    },
    '& div.profileName':{
        backgroundColor: '#FBEAE8 !important'
    }

})
interface ProfileModalProps {
    isProfileModalOpen: boolean,
    handleUserModalOptionOpen: () => void,
    handleNavigationPath: (path: string) => void,
}
const ProfileModal: React.FC<ProfileModalProps> = ({
    isProfileModalOpen,
    handleUserModalOptionOpen,
    handleNavigationPath
}) => {
    return (
        <Box>
            <CustomModal
                id="userOptionsId"
                open={isProfileModalOpen}
                onClose={handleUserModalOptionOpen}
            >
                <div style={userOptionsModalStyle.userOptionModalProfileWrapper}>
                    <div data-test-id="ProfileBtnId"
                        style={userOptionsModalStyle.userBasicProfileOptionStyle}
                        className='profileName'
                        onClick={() => {
                            handleNavigationPath("UserProfileBasicBlock")
                            handleUserModalOptionOpen()
                        }}
                    >
                        Profile
                    </div>
                    <div
                        data-test-id="LogoutBtnId"
                        style={userOptionsModalStyle.userLogoutOptionStyle}
                        onClick={() => {
                            localStorage.clear();
                            handleNavigationPath("Home")
                        }}
                    >
                        Logout
                    </div>
                </div>
            </CustomModal>
        </Box>
    )
}

export default ProfileModal