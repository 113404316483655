// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfmanagerelationshiptree from "../../blocks/cfmanagerelationshiptree/src/Cfmanagerelationshiptree";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfnetsuiteintegrationpaymentstatusupdate from "../../blocks/cfnetsuiteintegrationpaymentstatusupdate/src/Cfnetsuiteintegrationpaymentstatusupdate";
import Cfintegrationwithnetsuite2 from "../../blocks/cfintegrationwithnetsuite2/src/Cfintegrationwithnetsuite2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfintegrationwithnetsuitetransactiondetails from "../../blocks/cfintegrationwithnetsuitetransactiondetails/src/Cfintegrationwithnetsuitetransactiondetails";
import { UserProfileBlock } from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import Cfintegrationwithnetsuite from "../../blocks/cfintegrationwithnetsuite/src/Cfintegrationwithnetsuite";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import Cfcarduppaymentgatewayintegration from "../../blocks/cfcarduppaymentgatewayintegration/src/Cfcarduppaymentgatewayintegration";
import { ContactusBlock } from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Guestlogin2 from "../../blocks/guestlogin2/src/Guestlogin2";
import Analytics from "../../blocks/analytics/src/Analytics";
import { EmailAccountBlock } from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import { GoogleOAuthProvider } from '@react-oauth/google';


const routeMap = {
  Home: {
    component: SocialMediaAccountLogin,
    path: '/',
    exact: true
  },
  EmailAccountLoginBlock: {
    component: EmailAccountBlock,
    path: "/EmailAccountLoginBlock"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Cfmanagerelationshiptree: {
    component: Cfmanagerelationshiptree,
    path: "/Cfmanagerelationshiptree"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: "/DownloadOptions"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Cfnetsuiteintegrationpaymentstatusupdate: {
    component: Cfnetsuiteintegrationpaymentstatusupdate,
    path: "/Cfnetsuiteintegrationpaymentstatusupdate"
  },
  Cfintegrationwithnetsuite2: {
    component: Cfintegrationwithnetsuite2,
    path: "/Cfintegrationwithnetsuite2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Cfintegrationwithnetsuitetransactiondetails: {
    component: Cfintegrationwithnetsuitetransactiondetails,
    path: "/Cfintegrationwithnetsuitetransactiondetails"
  },
  UserProfileBasicBlock: {
    component: UserProfileBlock,
    path: "/UserProfileBasicBlock"
  },
  Cfintegrationwithnetsuite: {
    component: Cfintegrationwithnetsuite,
    path: "/Cfintegrationwithnetsuite"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  UserProfileAdvance: {
    component: UserProfileAdvance,
    path: "/UserProfileAdvance"
  },
  Cfcarduppaymentgatewayintegration: {
    component: Cfcarduppaymentgatewayintegration,
    path: "/Cfcarduppaymentgatewayintegration"
  },
  Contactus: {
    component: ContactusBlock,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Guestlogin2: {
    component: Guestlogin2,
    path: "/Guestlogin2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div style={{ height: '100%', width: '100%' }}>
        {/* <TopNav /> */} 
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
      </div>
    );
  }
}

export default App;