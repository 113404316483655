import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import KeyBoardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
const Cardup = require('./Cardup.png');
const arrowDown = require('./arrowdown.png');

const style = {
    modalWrapper: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        width:'100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: '10px 20px',
        borderRadius: "8px 8px 32px 8px",
        "@media(max-width: 767px)": {
            width: '70%'
          },
    },
    titleStyle: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ECEBEB'
    },
    titleLabelStyle: {
        fontSize: '20px',
        fontWeight: 700
    },
    closeIconStyle: {
        width: '32px',
        height: '32px',
        color: 'rgb(218, 41, 28)'
    },
    carduplable: {
        display: 'flex',
        justifyContent: 'center' as 'center',
        fontSize: '13px',
        color: '#7D7A78',
        fontWeight: 400,
        fontStyle: 'Inter',
        lineHeight: '26px',
        textAlign: 'center' as 'center',
    },
    transactionIDStyle: {
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left' as 'left',
        borderBottom: 'none',
        color: '#7D7A78',
        padding: '5px'
    },
    paymentAmountStyle: {
        display: 'flex',
        justifyContent: 'end',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left' as 'left',
        borderBottom: 'none',
        color: '#7D7A78',
        padding: '5px'

    },
    subTotalStyle: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left' as 'left',
        color: '#7D7A78',
        borderBottom: 'none',
        padding: '5px'

    },
    subtotalAmount: {
        display: 'flex',
        justifyContent: 'end',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'center' as 'center',
        color: '#7D7A78',
        padding: '5px',
        borderBottom: 'none',
    },
    viewTransactionWrapper:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor:'pointer'
    },
    viewTransactionTxt:{
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#7D7A78'
    },
    paymentTextWrapper: {
        display: "flex", 
        justifyContent: "center",
        borderBottom: "1px solid #ECEBEB", 
        paddingBottom: "1px", 
    },
    paymentTxt:{
        fontSize: "12px", 
        fontWeight: 500, 
        color: "black",
        textAlign: "center" as "center",
    },
    linkStyle:{
        textDecoration: "underline",
        cursor: "pointer",
        color:"black"
    },
    nextBtnStyle: {
        backgroundColor: 'rgb(218, 41, 28)',
        padding: '10px',
        color: 'white',
        fontWeight: 'bold' as 'bold'
    },
    tableStyle:{
        overflowY:'scroll' as 'scroll',
        maxHeight:'200px',
        padding: '0 10px',
        scrollbarColor: 'red',
        "&::-webkit-scrollbar": {
            width: '5px'
        },
        "&::-webkit-scrollbar-thumb": {
            background: 'red'
        }
    },
    subtotalTable:{
        width: '100%',
        padding: '8px',
        boxSizing: 'border-box'
    },
    nxtBtnBox:{
        padding: '10px 16px',
        cursor: 'pointer'
    }
};

interface AllPaymentModalProps {
    isAllPaymentModalOpen: boolean;
    handleAllPaymentModalClose: () => void;
    selectedData: any[];
    subtotal: number;
    handlePaymentModalNext: () => void;
    showAll: boolean;
    handleShowAllTransactions: () => void;
    totalAmount: number;
    pendingModalOpen: boolean;
    totalPendingTransactions: any;
}

const AllPaymentModal: React.FC<AllPaymentModalProps> = ({ 
    isAllPaymentModalOpen, 
    handleAllPaymentModalClose, 
    selectedData, 
    subtotal, 
    handlePaymentModalNext,
    showAll,
    handleShowAllTransactions,
    totalAmount,
    pendingModalOpen,
    totalPendingTransactions
}) =>
{
    const transactionsToDisplay = showAll ? selectedData : selectedData.slice(0, 5);
    const isNextDisabled = (pendingModalOpen ? Number(totalAmount) : Number(subtotal)) === 0;
    const transactionDisplay = showAll ? totalPendingTransactions : totalPendingTransactions?.slice(0, 5);
    
    return (
        <Box>
            <Modal
                data-test-id="all-payment-modal-id"
                open={isAllPaymentModalOpen}
                onClose={handleAllPaymentModalClose}
            >
                <Box sx={style.modalWrapper}>
                    <Box style={style.titleStyle}>
                        <Typography id="modal-modal-title" style={style.titleLabelStyle}>
                            Payment
                        </Typography>
                        <IconButton data-test-id="all-payment-modal-closeicon-id" onClick={handleAllPaymentModalClose}>
                            <CloseIcon style={style.closeIconStyle} />
                        </IconButton>
                    </Box>
                        <Box padding='24px 40px'>
                            <Box display='flex' justifyContent='center' marginBottom='20px'>
                                <img src={Cardup} width='120px' height='30px' alt="cardup" />
                            </Box>
                            <Typography style={style.carduplable}>
                                You are paying Canadian International School
                            </Typography>
                        </Box>
                        <Box sx={style.tableStyle}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={style.transactionIDStyle}>
                                            Transaction ID
                                        </TableCell>
                                        <TableCell style={style.paymentAmountStyle}>
                                            Payment Amount
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {pendingModalOpen ? (
                                transactionDisplay?.length > 0 && transactionDisplay.map((item:any, index:any) => (
                                    <TableBody key={index}>
                                    <TableRow>
                                        <TableCell style={style.transactionIDStyle}>
                                        {item.document_number}
                                        </TableCell>
                                        <TableCell style={style.paymentAmountStyle}>
                                        S${Number(item.amount).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                ))
                                ) : (
                                transactionsToDisplay.length > 0 && transactionsToDisplay.map((item, index) => (
                                    <TableBody key={index}>
                                    <TableRow>
                                        <TableCell style={style.transactionIDStyle}>
                                        {item.attributes.document_number}
                                        </TableCell>
                                        <TableCell style={style.paymentAmountStyle}>
                                        {String(item.attributes.status).toUpperCase() === "PAID" 
                                            ? `S$${item.attributes.amount.toFixed(2)}` 
                                            : `S$${Number(item.attributes.remaining_amount)?.toFixed(2)}`}
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                ))
                            )}
                            </Table>
                            {(pendingModalOpen ? totalPendingTransactions : selectedData)?.length > 0 &&
                                <>
                                <Box sx={style.viewTransactionWrapper}>
                                    {(pendingModalOpen ? totalPendingTransactions : selectedData)?.length > 5 && 
                                        <>
                                        {showAll && <KeyBoardArrowLeftIcon style={{color:'#7d7a78', width: "19px", height: "19px"}}/>}
                                        <Typography onClick={handleShowAllTransactions} style={style.viewTransactionTxt}>
                                            {showAll ? "View Less Transactions" :"View All Transactions"}
                                        </Typography>
                                        {!showAll && <img src={arrowDown} width='20px' height='20px' alt="downArrow" />}
                                        </>
                                    }
                                </Box>
                                <Box sx={{borderBottom: '1px solid #ECEBEB',width: '100%'}}>
                                </Box>
                                </>
                            }
                        </Box> 
                            <Box sx={style.subtotalTable}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={style.subTotalStyle}>
                                                Subtotal
                                            </TableCell>
                                            <TableCell style={style.subtotalAmount}>
                                                S$ {pendingModalOpen ? Number(totalAmount)?.toFixed(2) : Number(subtotal)?.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                    <Box style={style.paymentTextWrapper}>
                        <Typography style={style.paymentTxt} >
                            Please refer to the <a href="https://www.cis.edu.sg/admissions/fees" target="_blank" style={style.linkStyle}>link</a> for other payments methods.
                        </Typography>
                    </Box>
                    <Box sx={style.nxtBtnBox}>
                        <Button 
                         disabled={isNextDisabled}
                         data-test-id="paymentModalNextBtn" 
                         fullWidth 
                         style={style.nextBtnStyle} 
                         onClick={handlePaymentModalNext}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default AllPaymentModal