import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'
import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { BootstrapInputStyle } from '../../blocks/utilities/src/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'react-phone-input-2/lib/bootstrap.css'


const style = {
  modalWrapper: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: '500px',
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "12px 30px",
    width:"100%",
    borderRadius: "8px 8px 32px 8px",
    "@media(max-width: 767px)": {
      width: '70%'
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ECEBEB",
    height: "80px",
    padding: '0px 10px'
  },
  modalTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "left" as "left",
  },
  closeIconStyle: {
    width: "32px",
    height: "32px",
    color: "rgb(218, 41, 28)",
  },
  middleContainer: {
    overflowY: 'auto' as 'auto',
    maxHeight: 'calc(100vh - 120px)',
    padding: '10px 0',
    scrollbarColor: "lightgray rgba(0, 0, 0, 0.1)"
  },
  radioGroupStyle: {
    borderBottom: "1px solid #ECEBEB",
    padding: "10px 0px",
  },
  radioButtonLabelStyle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left" as "left",
    color: "#3D3935",
  },
  refundAmountStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "left" as "left",
    color: "#7D7A78",
  },
  amountStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "right" as "right",
  },
  inputLableStyle: {
    color: "#3D3935",
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 700,
    marginBottom: "6px",
  },
  inputLableStyle2: {
    color: "#3D3935",
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 700,
    marginBottom: "6px",
    marginTop: "20px"
  },
  subInputLableStyle: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left' as 'left'
  },
  astrixStyle: {
    color: "rgb(218, 41, 28)"
  },
  errorStyle: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left' as "left",
    color: '#DC2626',

  },
  selectStyle: {
    border: '1px solid #E0E0E0',
    padding: '14px',
    borderRadius: '10px'
  },
  submitbtn: {
    backgroundColor: "rgb(218, 41, 28)",
    padding: "10px",
    color: "white",
    fontWeight: "bold" as "bold",
    marginTop: "35px",
  },
  errorSelect: {
      border: '1px solid #E67067',
      padding: '14px',
      borderRadius: '10px',
  },
  phoneError: {
      border: '1px solid #E67067',
      borderRadius: '10px',
      width: '100%'
  }
};

const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

const Placeholder: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

interface RefundModalProps {
  isRefundModalOpen: boolean;
  handleRefundModalClose: () => void;
  handleRefundPaymentMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  refundPayment: string;
  payNowId: string;
  payNowName: string;
  contactNumber: string;
  payNowIdError: string;
  payNowNameError: string;
  contactNumberError: string;
  setPayNowId: (event: string) => void;
  setPayNowName: (event: string) => void;
  setContactNumber: (text: string, country: any) => void;
  handlePayNowSubmit: () => void;
  beneficiarysName: string;
  beneficiaryNameError: string;
  banksName: string;
  banksNameError: string
  accountNumber: string;
  accountNumberError: string;
  swiftCode: string;
  swiftCodeError: string;
  branchCode: string;
  bankCode: string;
  setBeneficiarysName: (event: string) => void;
  setBanksName: (event: string) => void;
  setAccountNumber: (event: string) => void;
  setSwiftCode: (event: string) => void;
  setBranchCode: (event: string) => void;
  setBankCode: (event: string) => void;
  handleByBankTransferSubmit: () => void;
  refundSubtotal: number;
  refundTotalAmount: number;
  refundPendingModalOpen:boolean;
  bankCodeError:string;
  branchCodeError:string;
}

const RefundModal: React.FC<RefundModalProps> = ({
  isRefundModalOpen,
  handleRefundModalClose,
  handleRefundPaymentMethod,
  refundPayment,
  payNowId,
  payNowName,
  contactNumber,
  payNowIdError,
  payNowNameError,
  contactNumberError,
  setPayNowId,
  setPayNowName,
  setContactNumber,
  handlePayNowSubmit,
  beneficiarysName,
  beneficiaryNameError,
  banksName,
  banksNameError,
  accountNumber,
  accountNumberError,
  swiftCode,
  swiftCodeError,
  branchCode,
  bankCode,
  setBranchCode,
  setBankCode,
  setBeneficiarysName,
  setBanksName,
  setAccountNumber,
  setSwiftCode,
  handleByBankTransferSubmit,
  refundSubtotal,
  refundTotalAmount,
  refundPendingModalOpen,
  bankCodeError,
  branchCodeError
}) => {
  const isNextDisabled = (refundPendingModalOpen ? Number(refundTotalAmount) : Number(refundSubtotal)) === 0;
  return (
    <Box  style={{ padding: "15px, 35px" }}>
      <Modal data-test-id="refund-modal-id" open={isRefundModalOpen}>
        <Box sx={style.modalWrapper}>
          <Box sx={style.titleWrapper}>
            <Typography
              id="modal-modal-title"
              style={style.modalTitle}
            >
              Request for Refund
            </Typography>
            <IconButton style={{padding:'0px'}} data-test-id="refund-modal-closeicon-id" onClick={handleRefundModalClose}>
              <CloseIcon
                style={style.closeIconStyle}
              />
            </IconButton>
          </Box>
          <Box style={style.middleContainer}>
            <Box display="flex" flexDirection="column" padding="0px 10px">
              <FormControl>
                <RadioGroup
                  row
                  data-test-id="refundMethod-id"
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={style.radioGroupStyle}
                  value={refundPayment}
                  onChange={handleRefundPaymentMethod}
                >
                  <FormControlLabel
                    value="by_bank_transfer"
                    control={<Radio color='secondary' />}
                    label="By Bank transfer"
                    style={style.radioButtonLabelStyle}
                  />
                  <FormControlLabel
                    value="by_pay_now"
                    control={<Radio color="secondary" />}
                    label="By Pay Now"
                    style={style.radioButtonLabelStyle}
                  />
                </RadioGroup>
              </FormControl>
              <Box display="flex" justifyContent="space-between" padding="20px 0px">
                <Typography style={style.refundAmountStyle}>
                  Refund Request Amount
                </Typography>
                <Typography style={style.amountStyle}>
                  S$ {refundPendingModalOpen ? Number(refundTotalAmount)?.toFixed(2) : Number(refundSubtotal)?.toFixed(2)}
                </Typography>
              </Box>
              {refundPayment === "by_pay_now" && (
                <>
                  <Box style={style.inputLableStyle}>
                    PayNow ID
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="payNowId" error={!!payNowIdError} fullWidth placeholder="0000" value={payNowId}
                    onChange={(e) => setPayNowId(e.target.value)} />
                  {payNowIdError && <Typography style={style.errorStyle}>{payNowIdError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    PayNow Name
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="payNowNameId" error={!!payNowNameError} fullWidth placeholder="Enter name" value={payNowName}
                    onChange={(e) => setPayNowName(e.target.value)} />
                  {payNowNameError && <Typography style={style.errorStyle}>{payNowNameError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    Contact Number
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <PhoneInput data-test-id="payContactId" inputStyle={contactNumberError ? style.phoneError : {width: "100%"}} country={'sg'} value={contactNumber}
                    onChange={(value, country) => setContactNumber(value, country)}
                  />
                  {contactNumberError && <Typography style={style.errorStyle}>{contactNumberError}</Typography>}
                </>
              )}
              {refundPayment === "by_bank_transfer" && (
                <>
                  <Box style={style.inputLableStyle}>
                    Beneficiary's Name<span style={style.subInputLableStyle}>(as per banks records)</span>
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="beneficiaryId" error={!!beneficiaryNameError} fullWidth placeholder="Benediciary name" value={beneficiarysName} onChange={(e) => setBeneficiarysName(e.target.value)} />
                  {beneficiaryNameError && <Typography style={style.errorStyle}>{beneficiaryNameError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    Bank's Name
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="bankNameId" error={!!banksNameError} fullWidth placeholder="Bank name" value={banksName} onChange={(e) => setBanksName(e.target.value)} />
                  {banksNameError && <Typography style={style.errorStyle}>{banksNameError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    Account Number
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="accountNoId" error={!!accountNumberError} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} fullWidth placeholder="e.g. SG HSBC 041-015587-001" />
                  {accountNumberError && <Typography style={style.errorStyle}>{accountNumberError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    Bank Code
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="bankCodeId" error={!!bankCodeError} value={bankCode} onChange={(e) => setBankCode(e.target.value)} fullWidth placeholder="0000" />
                  {bankCodeError && <Typography style={style.errorStyle}>{bankCodeError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    Branch Code
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="branchCodeId" error={!!branchCodeError} value={branchCode} onChange={(e) => setBranchCode(e.target.value)} fullWidth placeholder="000" />
                  {branchCodeError && <Typography style={style.errorStyle}>{branchCodeError}</Typography>}
                  <Box style={style.inputLableStyle2}>
                    SWIFT Code
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <BootstrapInputStyle data-test-id="swiftCodeId" error={!!swiftCodeError} value={swiftCode} onChange={(e) => setSwiftCode(e.target.value)} fullWidth placeholder="UOVBSGSG" />
                  {swiftCodeError && <Typography style={style.errorStyle}
                  >{swiftCodeError}</Typography>}
                  <Box style={style.inputLableStyle2}
                  >
                    Contact Number
                    <span style={style.astrixStyle}>*</span>
                  </Box>
                  <PhoneInput data-test-id="contactNoId" inputStyle={contactNumberError ? style.phoneError : {width: "100%"}} country={'sg'} value={contactNumber}
                    onChange={(value, country) => setContactNumber(value, country)} />
                  {contactNumberError && <Typography style={style.errorStyle}
                  >{contactNumberError}</Typography>}
                </>
              )}
              <Box sx={{borderBottom: '1px solid #ECEBEB',width: '100%', marginTop:'39px'}}></Box>
              <Box padding="10px 0px">
                <Button
                  data-test-id="refundSubmitId"
                  disabled={isNextDisabled}
                  onClick={refundPayment === "by_pay_now" ? handlePayNowSubmit : handleByBankTransferSubmit}
                  fullWidth
                  style={style.submitbtn}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default RefundModal