import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { addDays } from "date-fns";
import { getStorageData } from "../../../framework/src/Utilities";
import { getUserName } from "../../utilities/src/Colors";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from "moment";

const rows = [
  { id: 'no', numeric: false, disablePadding: true, label: 'No' },
  { id: 'Transactionid', numeric: true, disablePadding: false, label: 'Transaction ID' },
  { id: 'Memo', numeric: true, disablePadding: false, label: 'Memo' },
  { id: 'Date', numeric: true, disablePadding: false, label: 'Transaction Date' },
  { id: 'Due', numeric: true, disablePadding: false, label: 'Due Date' },
  { id: 'Status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'Download', numeric: true, disablePadding: false, label: 'Download' },
  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'Action', numeric: true, disablePadding: false, label: 'Action' },
];

const creditRows = [
  { id: 'no', numeric: false, disablePadding: true, label: 'No' },
  { id: 'Transactionid', numeric: true, disablePadding: false, label: 'Transaction ID' },
  { id: 'Memo', numeric: true, disablePadding: false, label: 'Memo' },
  // { id: 'Date', numeric: true, disablePadding: false, label: 'Transaction Date' },
  { id: 'Status', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'Download', numeric: true, disablePadding: false, label: 'Download' },
  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'Action', numeric: true, disablePadding: false, label: 'Action' },
];

const cardRows = [
  { id: 'no', numeric: false, disablePadding: true, label: 'No' },
  { id: 'Transactionid', numeric: true, disablePadding: false, label: 'Transaction ID' },
  { id: 'Memo', numeric: true, disablePadding: false, label: 'Memo' },
  { id: 'Date', numeric: true, disablePadding: false, label: 'Transaction Date' },
  { id: 'Download', numeric: true, disablePadding: false, label: 'Download' },
  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },
];

const soaRows = [
  { id: 'student', numeric: false, disablePadding: true, label: 'Student' },
  { id: 'Month', numeric: true, disablePadding: false, label: 'Month' },
  { id: 'Download', numeric: true, disablePadding: false, label: 'Download' },
];

const statusFilterData = [
  { id: 1, name: 'Pending' },
  { id: 2, name: 'Paid' },
  { id: 3, name: 'in_progress' },
  { id: 4, name: 'Cancel' },
  { id: 5, name: 'Refunded' },
  { id: 6, name: 'Overdue' },
]

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  orderBy: any;
  order: any;
  selected: any[];
  invoiceStatus:boolean;
  creditStatus:boolean;
  cardStatus:boolean;
  soaStatus: boolean;
  refund:boolean,
  pay:boolean,
  tableHeaderData: any[];
  expandedRow: number;
  isSelectAll: boolean;
  selectedToggleTab: string;
  userName: string;
  isUserOptionModalOpen: boolean;
  actionsFilter: any[], 
  filterDropDownOpen: string,
  isFilterOpen: boolean,
  selectedFilter: string,
  allTransactionIdData: any[],
  calendarRanges: any[],
  userData: any;
  isAllPaymentModalOpen: boolean;
  isRefundModal: boolean;
  refundPaymentMethod:string,
  isInstallmentModalOpen: boolean,
  installmentId: string,
  installmentAmount:any,
  payingAmount:string,
  payingAmountError:string,
  payNowId: string;
  payNowName: string;
  contactNumber: string;
  contactNumberFormat: string;
  contactNumberError: string;
  payNowIdError: string;
  payNowNameError: string;
  beneficiarysName:string;
  beneficiarysNameError:string;
  banksName:string;
  banksNameError:string;
  accountNumber:string;
  accountNumberError:string;
  swiftCode:string;
  swiftCodeError:string;
  branchCode:string;
  bankCode:string;
  headerUserProfilePic: any;
  selectedRows:any[];
  orderTransactionData: any[];
  apiError: boolean;
  subtotal:number;
  showLoader: boolean;
  page:number;
  pageData: any;
  selectionMode:string;
  totalPages: any[];
  childDetails: any[];
  pageNumbers: number;
  tableBodyNumValue: number;
  isProfileUpdateStatusModal: boolean;
  profileUpdateStatus: string;
  isProfileDetailsFetch: boolean;
  filterName: string;
  formattedStartDate: string;
  formattedEndDate: string;
  cardupCustomerMessage:string;
  sideBarOpen:boolean;
  cardupRequestId: string;
  showAllTransactions:boolean;
  totalAmount:number;
  refundTotalAmount:number;
  refundSubtotal:number;
  pendingModalOpen:boolean;
  totalPendingTransactions:any;
  isPaymentResponseModalOpen:boolean;
  paymentResponseMessage:string;
  refundMessage:boolean;
  isPaymentFailed:boolean;
  lastSyncMessage:string;
  refundPendingModalOpen:boolean;
  currentFilters: { [key: string]: any };
  loggedInToken:string;
  bankCodeError:string;
  branchCodeError:string;
  isSyncButtonRotating:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfnetsuiteintegrationpaymentstatusupdateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  transactionDataApiCallId: string = "";
  getUsersDetailsApiCallId: string = "";
  getSoatransactionDataApiCallId: string = "";
  getTransactionDataIdApiCallId: string = "";
  getCardupCustomerApiCallId: string = "";
  debounceTimeout: number = 2000;
  generatePaymentApiCallId: string = "";
  generateRefundApiCallId: string = "";
  getSyncTransactionDataApiCallId: string = "";
  getPaymentStatusResponseMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      invoiceStatus:true,
      creditStatus:false,
      cardStatus:false,
      soaStatus: false,
      refund:false,
      pay:false,
      tableHeaderData: rows,
      expandedRow: 0,
      isSelectAll: false,
      selectedToggleTab: "All",
      userName: "",
      isUserOptionModalOpen: false,
      actionsFilter: statusFilterData,
      filterDropDownOpen: '',
      isFilterOpen: false,
      selectedFilter: '',
      allTransactionIdData: [],
      calendarRanges: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 1),
          key: 'selection'
        }
      ],
      userData: null,
      isAllPaymentModalOpen:false,
      isRefundModal:false,
      refundPaymentMethod:'by_bank_transfer',
      isInstallmentModalOpen:false,
      installmentId:'',
      installmentAmount:'',
      payingAmount:'',
      payingAmountError:'',
      payNowId: "",
      payNowName: "",
      contactNumber: "",
      contactNumberFormat:"",
      payNowIdError: "",
      payNowNameError: "",
      contactNumberError: "",
      beneficiarysName:"",
      beneficiarysNameError:"",
      banksName:"",
      banksNameError:"",
      accountNumber:"",
      accountNumberError:"",
      swiftCode:"",
      swiftCodeError:"",
      branchCode:"",
      bankCode:"",
      headerUserProfilePic: null,
      selectedRows:[],
      orderTransactionData: [],
      apiError: false,
      subtotal:0,
      showLoader: true,
      page:1,
      pageData:{},
      selectionMode: "CustInvc",
      totalPages:[],
      childDetails: [],
      pageNumbers: 1,
      tableBodyNumValue: 0,
      isProfileUpdateStatusModal:false,
      profileUpdateStatus: "",
      isProfileDetailsFetch: false,
      sideBarOpen:window.innerWidth >= 768,
      filterName: "",
      formattedStartDate:"",
      formattedEndDate:"",
      cardupCustomerMessage:"",
      cardupRequestId: "",
      showAllTransactions:false,
      totalAmount:0,
      pendingModalOpen:false,
      totalPendingTransactions:[],
      isPaymentResponseModalOpen:false,
      paymentResponseMessage:"",
      refundMessage:false,
      isPaymentFailed:false,
      lastSyncMessage:"",
      refundTotalAmount:0,
      refundSubtotal:0,
      refundPendingModalOpen:false,
      currentFilters:{},
      loggedInToken:"",
      bankCodeError:"",
      branchCodeError:"",
      isSyncButtonRotating:false
      // Customizable Area End
    };
    console.log(this.state.invoiceStatus,"this.state.invoiceStatus")
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null && responseJson) {
       this.orderTransactionApiResponse(apiRequestCallId, responseJson)
       this.handleUserProfileDetailsResponse(apiRequestCallId, responseJson)
       this.SoaTransactionDataResponse(apiRequestCallId, responseJson)
       this.getTransactionDataIdApiResponse(apiRequestCallId, responseJson)
       this.handleCardupCustomerApiResponse(apiRequestCallId, responseJson)
       this.handlePaymentGenerateApiResponse(apiRequestCallId, responseJson)
       this.handleRefundGenerateApiResponse(apiRequestCallId, responseJson)
       this.handleSyncTransactionApiResponse(apiRequestCallId, responseJson)
       this.handlePaymentStatusResponseMessageApiResponse(apiRequestCallId, responseJson)
      }
    }


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  commonSetUpdateTransactionIdData = (data: any) => {
    const initialFilterAllIdData = data.map((item: any) => item.attributes.document_number)
    this.setState({ 
      allTransactionIdData: initialFilterAllIdData
    })
  }

  setInitialTransactionIds = () => {

      this.commonSetUpdateTransactionIdData(this.state.orderTransactionData)
  }

  changePayment = (data:string) => {
    if(data=== "Invoice"){
      this.setState({ 
        invoiceStatus:true, creditStatus:false, cardStatus:false, soaStatus: false, 
        tableHeaderData: rows, isSelectAll: false, selectionMode: "CustInvc",
        pay: false, refund: false,
      }, () => {
        this.setInitialTransactionIds()
        this.handleApiEndpoint(1, this.state.selectionMode, "", false)
      })
    }else if(data=== "Credit"){
      this.setState({ 
        creditStatus:true, invoiceStatus:false, cardStatus:false, soaStatus: false, 
        tableHeaderData: creditRows, isSelectAll: false, selectionMode: "CustCred",
        pay: false, refund: false,
      }, () => {
        this.setInitialTransactionIds()
        this.handleApiEndpoint(1, this.state.selectionMode, "", false)
      })
    }else if(data=== "Cash"){
      this.setState({ 
        cardStatus:true, invoiceStatus:false, creditStatus:false, soaStatus: false,
        tableHeaderData: cardRows, selectionMode:"CashSale",
        pay: false, refund: false,
      }, () => {
        this.setInitialTransactionIds()
        this.handleApiEndpoint(1, this.state.selectionMode, "", false)
      })
    }else if(data=== "SOA"){
      this.setState({ 
        soaStatus: true, cardStatus:false, invoiceStatus:false, creditStatus:false, 
        tableHeaderData: soaRows, pay: false, refund: false, selectionMode:"Soa"
      })

      this.handleApiEndpoint(1, "", "", true)
    }
    this.saveCurrentTab();
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData("Token")
    this.getUserProfileDetailsApiCall()
    const userDataFromStorage = await getStorageData("UserData", true);
    userDataFromStorage && this.setState({
      ...this.state,
      loggedInToken: token
    })
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {      
      this.restoreSavedTab(savedTab)
    }
    const savedToggleTab = localStorage.getItem('selectedToggleTab');
    if (savedToggleTab) {
      this.restoreSavedToggleTab(savedToggleTab);
    }
    const savedPaymentIds = localStorage.getItem('paymentIds');
    const parsedItem = savedPaymentIds && JSON.parse(savedPaymentIds);
    
    if(parsedItem) {
      this.getPaymentStatusResponseMessage(parsedItem);
    }
    this.getCardupCustomerApiCall()
    this.handleApiEndpoint(this.state.page, this.state.selectionMode, "", this.state.soaStatus)
    this.setState({showLoader:true})
    window.addEventListener('resize', this.handleResize);
  }

  async componentDidUpdate(prevProps:any, prevState:any) {
    if (
      prevState.invoiceStatus !== this.state.invoiceStatus ||
      prevState.creditStatus !== this.state.creditStatus ||
      prevState.cardStatus !== this.state.cardStatus ||
      prevState.soaStatus !== this.state.soaStatus
    ) {
      this.saveCurrentTab();
    }
    if (prevState.selectedToggleTab !== this.state.selectedToggleTab) {
      localStorage.setItem('selectedToggleTab', this.state.selectedToggleTab);
    }
    if (prevState.lastSyncMessage !== this.state.lastSyncMessage) {
        this.setState({ isSyncButtonRotating: false, lastSyncMessage:"" });
    }
 }

  saveCurrentTab = () => {
    if (this.state.invoiceStatus) {
      localStorage.setItem('activeTab', 'Invoice');
    } else if (this.state.creditStatus) {
      localStorage.setItem('activeTab', 'Credit');
    } else if (this.state.cardStatus) {
      localStorage.setItem('activeTab', 'Cash');
    } else if (this.state.soaStatus) {
      localStorage.setItem('activeTab', 'SOA');
    }
  }

  restoreSavedTab = (data:string) => {
    if(data === "Invoice"){
      this.setState({ 
        invoiceStatus:true, creditStatus:false, cardStatus:false, soaStatus: false, 
        tableHeaderData: rows, isSelectAll: false, selectionMode: "CustInvc", 
        pay: false, refund: false,
      })
    }
    else if(data=== "Credit"){
      this.setState({ 
        creditStatus:true, invoiceStatus:false, cardStatus:false, soaStatus: false, 
        tableHeaderData: creditRows, isSelectAll: false, selectionMode: "CustCred",
        pay: false, refund: false,
      })
    }
    else if(data=== "Cash"){
      this.setState({ 
        cardStatus:true, invoiceStatus:false, creditStatus:false, soaStatus: false,
        tableHeaderData: cardRows, selectionMode:"CashSale",
        pay: false, refund: false,
      })
    }
    else if(data=== "SOA"){
      this.setState({ 
        soaStatus: true, cardStatus:false, invoiceStatus:false, creditStatus:false, 
        tableHeaderData: soaRows, pay: false, refund: false, selectionMode:"Soa"
      })
    }
  }

  restoreSavedToggleTab = (data:string) => {
    this.setState({ selectedToggleTab: data });
  }

  actionData = (transactionType:string, status:string) => {
    if(transactionType === "CustInvc" && (status === "PENDING" ||status === "OVERDUE")){
      return "Pay"
    }
    if(transactionType === "CustCred" && status === ("PENDING")){
      return "Refund"
    }
    return ""
  }

  orderTransactionApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    if(apiRequestCallId === this.transactionDataApiCallId){
      if(responseJson.data){
        const currentPage = responseJson.meta.current_page;
        const itemsPerPage = 10;
        const startingIndex = (currentPage - 1) * itemsPerPage + 1;
        const updatedData = await responseJson.data.map((item: any, index: number) => { 
          return { 
            ...item, 
            isSelected: false, 
            numIndex: startingIndex + index,
            Action: this.actionData(item.attributes.transaction_type, String(item.attributes.status).toUpperCase()),
            
          }
        })

        this.setState({
          ...this.state,
          orderTransactionData: updatedData,
          apiError: false,
          pageData: responseJson.meta,
          tableBodyNumValue: startingIndex + itemsPerPage - 1,
          page: currentPage,
          totalPendingTransactions: responseJson.meta.total_pending_invoice_ids
        })
        if( responseJson.meta.current_page ===1 && responseJson.meta.total_count > 0 ){
          this.getPageNumbers(responseJson.meta.count, responseJson.meta.total_count)
        }
      }
       else if(responseJson.errors) {
        this.setState({
          ...this.state,
          apiError: true,
          tableBodyNumValue : 0,
          page: 1,
        })
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.token) {
          this.setState({
            isProfileUpdateStatusModal:true,
            isProfileDetailsFetch:true,
            profileUpdateStatus: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({showLoader:false})
    }
  }

  SoaTransactionDataResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getSoatransactionDataApiCallId){
      if(responseJson.data){
        this.setState({
          ...this.state,
          orderTransactionData: responseJson.data,
          apiError: false,
          pageData: responseJson.meta,
          page: responseJson.meta.current_page
        })
        if( responseJson.meta.current_page ===1 && responseJson.meta.total_count > 0 ){
          this.getPageNumbers(responseJson.meta.count, responseJson.meta.total_count)
        }
      } else if(responseJson.errors) {
        this.setState({
          ...this.state,
          apiError: true,
          page: 1
        })
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.token) {
          this.setState({
            isProfileUpdateStatusModal:true,
            isProfileDetailsFetch:true,
            profileUpdateStatus: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({showLoader:false})
    }
  }

  getTransactionDataIdApiResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getTransactionDataIdApiCallId){
      if(responseJson && responseJson.data){
        this.setState({
         ...this.state,
          apiError:false,
        })
        this.commonSetUpdateTransactionIdData(responseJson.data)
      } else if(responseJson.errors){
        this.setState({
         ...this.state,
          apiError:true,
        })
      }
    }
  }

  handleUserProfileDetailsResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getUsersDetailsApiCallId){
      if(responseJson && responseJson.data){
        this.setState({
         ...this.state,
          userData: responseJson.data,
          userName: getUserName(responseJson.data)	,
          headerUserProfilePic: responseJson.data.attributes.avatar,
          childDetails: responseJson.data.attributes.childs,
          apiError:false,
        })
      } else if(responseJson.errors){
        this.setState({
         ...this.state,
          apiError:true,
          isProfileUpdateStatusModal:true,
          isProfileDetailsFetch:true,
          profileUpdateStatus: configJSON.sessionExpiredMessage
        })
      }
      this.setState({showLoader:false})
    }
  }

  handleCardupCustomerApiResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.getCardupCustomerApiCallId){
      if(responseJson && responseJson.message){
        this.setState({
         ...this.state,
          cardupCustomerMessage: responseJson.message,
          apiError:false,
        })
      } else if(responseJson.errors){
        this.setState({
         ...this.state,
          apiError:true,
        })
      }
    }
  }
  
  handlePaymentGenerateApiResponse = (apiRequestCallId:string, responseJson: any)=>{
    if(apiRequestCallId === this.generatePaymentApiCallId){
      if(responseJson && responseJson.data){
        this.setState({
         ...this.state,
          apiError:false,
          cardupRequestId: responseJson.data.attributes.cardup_request_id
        })
      this.redirectToCheckout();
      } else if(responseJson.errors){
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.token) {
          this.setState({
            apiError:true,
            isProfileUpdateStatusModal:true,
            isProfileDetailsFetch:true,
            profileUpdateStatus: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({showLoader:false})
    }
  }

  handleRefundGenerateApiResponse = (apiRequestCallId:string, responseJson:any) => {
    if(apiRequestCallId === this.generateRefundApiCallId){
      if(responseJson && Array.isArray(responseJson) && responseJson.length > 0){
        this.setState({
         ...this.state,
          apiError:false,
          isPaymentResponseModalOpen:true,
          paymentResponseMessage:"Refund Request",
          refundMessage:true,
          isPaymentFailed:false,
          refundPaymentMethod:"by_bank_transfer",
          isRefundModal:false,
          refundPendingModalOpen: false,
          refundSubtotal: 0,
          refundTotalAmount: 0,
          beneficiarysName: "",
          banksName: "",
          accountNumber: "",
          swiftCode: "",
          bankCode: "",
          branchCode: "",
          contactNumber: "",
          contactNumberError: "",
          banksNameError: "",
          swiftCodeError: "",
          accountNumberError: "",
          beneficiarysNameError: "",
          payNowId: "",
          payNowName: "",
          payNowIdError: "",
          payNowNameError: "",
          refund:false,
          bankCodeError: "",
          branchCodeError: ""
        })
        this.handleApiEndpoint(1, this.state.selectionMode, "", false)
      }
      else if(responseJson.errors){
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.contact_number) {
          this.setState({
            ...this.state, 
            contactNumberError: "Enter valid contact number", 
            apiError:true 
          });
        }
        if (error.token) {
          this.setState({
            apiError:true,
            isProfileUpdateStatusModal:true,
            isProfileDetailsFetch:true,
            profileUpdateStatus: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({
        showLoader:false
      })
    }
  }
  
  handleSyncTransactionApiResponse = (apiRequestCallId:string, responseJson:any) =>  {
    if(apiRequestCallId === this.getSyncTransactionDataApiCallId){
      if(responseJson && responseJson.message) {
        this.setState({
         ...this.state,
          apiError:false,
          lastSyncMessage: responseJson.message
        })
        this.state.soaStatus ? 
        this.handleApiEndpoint(this.state.page, "", "", true) : 
        this.handleApiEndpoint(this.state.page, this.state.selectionMode, "", false)
      } else if(responseJson.errors){
        Array.isArray(responseJson.errors) && responseJson.errors.forEach((error: any) => {
          if (error.token) {
          this.setState({
            apiError:true,
            isProfileUpdateStatusModal:true,
            isProfileDetailsFetch:true,
            profileUpdateStatus: configJSON.sessionExpiredMessage
          });
        }})
      }
      this.setState({showLoader:false})
    } 
  }

  handlePaymentStatusResponseMessageApiResponse = (apiRequestCallId:string, responseJson:any) =>  {
    if(apiRequestCallId === this.getPaymentStatusResponseMessageApiCallId){
      if(responseJson && responseJson.message){
        this.setState({
         ...this.state,
          apiError: false,
          paymentResponseMessage: responseJson.message,
          isPaymentResponseModalOpen:true,
          isPaymentFailed: responseJson.message === "Payment Successful" ? false : true
        })
      }
      else if(responseJson.errors){
        this.setState({
         ...this.state,
          apiError: true,
        })
      }
      this.setState({showLoader:false})
    }
  }

  handleApiEndpoint = (page:number, transactionType: string, filter:string, soaStatus:boolean) => {
    const childId = this.state.selectedToggleTab === "All" ? "" : this.state.selectedToggleTab; 
    if(soaStatus){
      const baseEndpoint = `${configJSON.getSoaDataApiEndPoint}?page=${page}&child_cis_id=${childId}`
      this.getSoaTransactionApiCall(baseEndpoint)
      this.setState({showLoader:true})

    }
    else{
      const baseEndpoint = `${configJSON.orderTransactionApiEndPoint}?page=${page}&transaction_type=${transactionType}&child_cis_id=${childId}&${filter}`
      this.getTransactionData(baseEndpoint) 
      this.setState({showLoader:true})

    }
  }

  redirectToCheckout = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('https://epay.cis.edu.sg/') && (process.env.NODE_ENV === 'production')) {
        window.location.href = `${configJSON.prodApiEndPoint}/${this.state.cardupRequestId}`;
    }
    else{
      window.location.href = `${configJSON.sandboxApiEndPoint}/${this.state.cardupRequestId}`;
    }
  }

  handleSyncClick = () => {
    this.setState({ isSyncButtonRotating: true });
    this.getSyncTransactionApiCall();
  };

  getPageNumbers = (count:number, total_count:number) => {
    let totalPages = Math.ceil(total_count / count);
    this.setState({ pageNumbers: totalPages });
  };

  handlePageChange = (pageNum:number) => {
    if (this.state.page !== pageNum) {
      const currentFilters = { ...this.state.currentFilters };
  
      if (Object.keys(currentFilters).length > 0) {
        this.updateFilters(currentFilters, pageNum);
      } else {
        this.handleApiEndpoint(pageNum, this.state.selectionMode, "", false);
      }
    }
  }

  getTransactionData = (endpoint: string) => {  
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.loggedInToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.transactionDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfileDetailsApiCall = async() => {
    
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUsersDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCardupCustomerApiCall = async () => {
    const authToken = await getStorageData("Token")
    
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardupCustomerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCardupCustomerResponseEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSoaTransactionApiCall = async (endpoint: string) => {
    
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSoatransactionDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTransactionDataId = async (transactionId: string)=> {
    const endpoint = `${configJSON.getTransactionDataIdApiEndpoint}?query=${transactionId}&${this.state.page}&transaction_type=${this.state.selectionMode}`
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionDataIdApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  generatePaymentApiCall = async (installment:boolean, amount:number, ids?:number) => {
    const selectedRows = this.state.selectedRows.map((item:any) => item.attributes.ns_internal_id)
    const Id = (selectedRows.length > 0) ? selectedRows : ids;  
    const body = installment ? 
      {
        "ids": [this.state.installmentId],
        "charge_reference": "testing payment", "installment": installment, "amount": amount
      } :
      {"ids": Id, "charge_reference": "testing payment"}    
    
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generatePaymentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generatePaymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  generateRefundApiCall = async (body: FormData) => {
    const authToken = await getStorageData("Token")
    const header = {
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateRefundApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateRefundApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getSyncTransactionApiCall = async () => {
    this.setState({ showLoader: true });
    const authToken = await getStorageData("Token")
    
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSyncTransactionDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSyncTransactionEndPoint 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPaymentStatusResponseMessage = async (transactionId:any) => {
    const endpoint = `${configJSON.getPaymentStatusResponseMessage}?ns_internal_ids=[${transactionId}]`
    this.setState({ showLoader: true });
    const authToken = await getStorageData("Token")
    
    const header = {
      token: authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentStatusResponseMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  toggle = async (data: string) =>{
    await this.setState({ selectedToggleTab: data })
    const {selectionMode} = this.state;

      this.handleApiEndpoint(1, selectionMode, "", this.state.soaStatus)
      localStorage.setItem('selectedToggleTab', data);
  }

  payStatus = (data:string, amount:string) => {   
    if (amount === '0.00') {
      return; 
    }
    if(data==="pay"){
      this.handlePendingModalOpen(amount)
    }else{
      this.handleRefundPendingModalOpen(amount)
    }  
  }

  handleNestedTableOpen = (rowId: any) => {
    if(this.state.expandedRow === rowId) {
      this.setState({ expandedRow: 0 });
    } else {
      this.setState({ expandedRow: rowId });
    }
  }

  handleSelectAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    
    const updatedData = this.state.orderTransactionData.map((item) => { return { ...item, isSelected: checked} })

    const { subtotal, refundSubtotal, shouldOpenModal } = this.calculateSubtotalAndModal(updatedData);

    this.setState({ 
      isSelectAll: checked, 
      orderTransactionData: updatedData, 
      pay: this.state.invoiceStatus && shouldOpenModal,
      refund: this.state.creditStatus && shouldOpenModal,
      subtotal: subtotal,
      selectedRows: updatedData.filter((item) => item.isSelected),
      refundSubtotal: refundSubtotal,
    });
  }

  handleSelectRowItem = (event: React.ChangeEvent<HTMLInputElement>, transactionid: number | string) => {
    const { checked } = event.target;
    const updatedData = this.state.orderTransactionData.map((item) =>
      item.attributes.ns_internal_id === transactionid ? { ...item, isSelected: checked } : item
    );
  
    const { subtotal, refundSubtotal, shouldOpenModal } = this.calculateSubtotalAndModal(updatedData);
  
    this.setState((prevState) => ({
      orderTransactionData: updatedData,
      isSelectAll: prevState.isSelectAll && updatedData.every((item) => item.isSelected),
      pay: this.state.invoiceStatus && shouldOpenModal,
      refund: this.state.creditStatus && shouldOpenModal,
      selectedRows: updatedData.filter((item) => item.isSelected),
      subtotal: subtotal,
      refundSubtotal: refundSubtotal
    }));
  };
  
  calculateSubtotalAndModal = (updatedData: any[]) => {
    let subtotal = 0;
    let refundSubtotal = 0;
    let shouldOpenModal = false;
  
    const selectedRows = updatedData.filter((item) => item.isSelected);
  
    if (this.state.invoiceStatus) {
      const allSelectedRowsArePendingOrOverdue = selectedRows.every(
        (item) => String(item.attributes.status).toUpperCase() === 'PENDING' || String(item.attributes.status).toUpperCase() === 'OVERDUE'
      );
      subtotal = this.calculateSubtotal(selectedRows);
  
      if (allSelectedRowsArePendingOrOverdue && selectedRows.length > 1) {
        shouldOpenModal = true;
      }
    } else if (this.state.creditStatus) {
      const allSelectedRowsArePending = selectedRows.every((item) => String(item.attributes.status).toUpperCase() === 'PENDING');
      refundSubtotal = this.calculateSubtotal(selectedRows);
  
      if (allSelectedRowsArePending && selectedRows.length > 1) {
        shouldOpenModal = true;
      }
    }
  
    return { subtotal, refundSubtotal, shouldOpenModal };
  };
  
  calculateSubtotal = (selectedRows: any[]) => {
    return selectedRows.reduce((total, item) => {
      const amountString = String(item.attributes.status).toUpperCase() === "PAID" 
      ? `${item.attributes.amount.toFixed(2)}` 
      : `${Number(item.attributes.remaining_amount).toFixed(2)}`
      const amountNumberString = amountString
      const amount = parseFloat(amountNumberString);
      return isNaN(amount) ? total : total + amount;
    }, 0);
  };

  handleUserOtherOption = () => {
    this.setState({ isUserOptionModalOpen: !this.state.isUserOptionModalOpen })
  }

  handleResize = () => {
    this.setState({ sideBarOpen: window.innerWidth >= 768 });
  }

  handleSideBar = () =>{
    this.setState(prevState=>({
      sideBarOpen:!prevState.sideBarOpen
    }))
  }

  handlePathNavigation = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  handleSessionExpireModal = (path: string) => {
    this.setState({
      ...this.state,
      isProfileUpdateStatusModal: false
    })
    this.handlePathNavigation(path)
  }

  handlePaymentResponseModalClose = () => {
    localStorage.removeItem('paymentIds');
    localStorage.removeItem('isAllPaymentModalOpen');
    localStorage.removeItem('totalAmount');
    localStorage.removeItem('selectedRows');
    localStorage.removeItem('pendingModalOpen');
    localStorage.removeItem('installmentModalOpen');
    this.setState({ isPaymentResponseModalOpen: false})
  }
 
  handleSetInitialFilters = () => {
    if(this.state.isFilterOpen){
      
      this.handleApiEndpoint(1, this.state.selectionMode, "", false)
    }
    this.setState({
      isFilterOpen: false,
      selectedFilter: '',
      calendarRanges: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 1),
          key: 'selection'
        }
      ],
      filterName:"",
      currentFilters:{}
    })
  }

  handleSetInitialFilterStateValues = (filterToRemove:string) => {
    this.setState(prevState => {
      const updatedFilters = { ...prevState.currentFilters };
      let updatedCalendarRanges = prevState.calendarRanges;
      let updatedSelectedFilter = prevState.selectedFilter;
      let updatedFilterName = prevState.filterName;

      if (filterToRemove === "transactionId"){
        delete updatedFilters.documentNumber;
      }
      else if (filterToRemove === 'status') {
        delete updatedFilters.status;
      } 
      else if (filterToRemove === 'transactionDate') {
        delete updatedFilters.startDate;
        delete updatedFilters.endDate;
        updatedCalendarRanges = [{
          startDate: new Date(),
          endDate: addDays(new Date(), 1),
          key: 'selection'
        }];
      }

      if (prevState.filterName === filterToRemove) {
        updatedSelectedFilter = '';
        updatedFilterName = '';
      }
  
      return {
        isFilterOpen: false,
        selectedFilter: updatedSelectedFilter,
        calendarRanges: updatedCalendarRanges,
        filterName: updatedFilterName,
        currentFilters: updatedFilters
      };
    }, () => {

      const filterString = this.buildFilterString(this.state.currentFilters);
      this.handleApiEndpoint(1, this.state.selectionMode, filterString, false);
    });
  }

  filterDropDownButton = (rowId: string) => {
    if(this.state.filterDropDownOpen === rowId){
    this.setState({ isFilterOpen: false, filterDropDownOpen:"" })

    }
    else{

      this.setState({ filterDropDownOpen: rowId, isFilterOpen: true })
    }
    this.setInitialTransactionIds()
  }

  handleTransactionIdFilter = (transactionid: string) => {
    if (this.state.currentFilters.documentNumber === transactionid) {
      this.handleSetInitialFilterStateValues("transactionId")

      return
    }
    
    this.updateFilters({ documentNumber: transactionid });

    this.setState({ 
      isFilterOpen: false, 
      selectedFilter: transactionid,
      filterDropDownOpen:"",
      filterName:"document_number"
    });
  }

  handleStatusFilter = (status: string) => {
    if (this.state.currentFilters.status === status) {
      this.handleSetInitialFilterStateValues("status")

      return
    }
    this.updateFilters({ status: status });
    this.setState({ 
      isFilterOpen: false,
      selectedFilter: status,
      filterDropDownOpen:"",
      filterName:"status"
    });
  }

  handleTransactionIdSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if(value === "") {
      this.setInitialTransactionIds();
    } 
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = window.setTimeout(() => {
      this.getTransactionDataId(value);
    }, 500);
  }

  handleDateRangeOnChange  = (date: any) => {
    if(!date){
      return;
    }
    
    const startParsedDate = moment.parseZone(date.startDate);
    const endParsedDate = moment.parseZone(date.endDate);
    const formattedStartDate = startParsedDate.format("YYYY-MM-DD");
    const formattedEndDate = endParsedDate.format("YYYY-MM-DD");

    if (this.state.currentFilters.startDate === formattedStartDate && 
      this.state.currentFilters.endDate === formattedEndDate) {    
      this.handleSetInitialFilterStateValues("transactionDate")
      return
    }

    const updateFilterDateRange = {
      startDate: date.startDate,
      endDate: date.endDate,
      key: "selection"
    };

    this.setState(prevState => ({
      calendarRanges: [updateFilterDateRange],
      isFilterOpen: false,
      selectedFilter: date.key,
      filterDropDownOpen: "",
      filterName: "transactionDate",
      formattedStartDate: formattedStartDate,
      formattedEndDate: formattedEndDate,
      currentFilters: {
        ...prevState.currentFilters,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      }
    }), () => {
      this.applyFilters();
    });
  }

buildFilterString = (filters:any) => {
  const filterParams = [];

  if (filters.documentNumber) {
    filterParams.push(`document_number=${filters.documentNumber}`);
  }
  if (filters.status) {
    filterParams.push(`status=${filters.status}`);
  }
  if (filters.startDate && filters.endDate) {
    filterParams.push(`start_date=${filters.startDate}&end_date=${filters.endDate}`);
  }

  return filterParams.join('&');
}

updateFilters = (newFilters:any, pageNum?:number) => {
  
  this.setState(prevState => {
    const updatedFilters = {
      ...prevState.currentFilters,
      ...newFilters
    };
    return { currentFilters: updatedFilters };
  }, () => {
    this.applyFilters(pageNum);
  });
}

applyFilters = (pageNum?:number) => {
  const { currentFilters } = this.state;
  
  const filterString = this.buildFilterString(currentFilters);
  if(pageNum) {    
    this.handleApiEndpoint(pageNum, this.state.selectionMode, filterString, false);
  }else{
    this.handleApiEndpoint(1, this.state.selectionMode, filterString, false);
  }
}

  handlePaymentModalOpen = () => {
    const updatedData = this.state.orderTransactionData.filter(item=> item.isSelected)
    this.setState({
      isAllPaymentModalOpen:true,
      selectedRows:updatedData
    }, () => {
      localStorage.setItem('isAllPaymentModalOpen', JSON.stringify(true));
      localStorage.setItem('selectedRows', JSON.stringify(updatedData));
      localStorage.setItem('totalAmount', JSON.stringify(Number(this.state.subtotal)))
    })
  }

  handleRefundPaymentModalOpen = () => {
    const updatedData = this.state.orderTransactionData.filter(item=> item.isSelected)
    this.setState({
      isRefundModal:true,
      selectedRows:updatedData
    })
  }

  handlePendingModalOpen = (amount:string) => {
    const updatedData = this.state.orderTransactionData.filter((item)=>
    String(item.attributes.status)?.toUpperCase() === "PENDING" && this.state.invoiceStatus)
    this.setState({
      isAllPaymentModalOpen:true,
      totalAmount:Number(amount),
      selectedRows:updatedData,
      pendingModalOpen:true
    }, () => {
      localStorage.setItem('isAllPaymentModalOpen', JSON.stringify(true));
      localStorage.setItem('totalAmount', JSON.stringify(Number(amount)));
      localStorage.setItem('selectedRows', JSON.stringify(updatedData));
      localStorage.setItem('pendingModalOpen', JSON.stringify(true));
    })
  }

   handlePayAgain = () => {
    const selectedRows = JSON.parse(localStorage.getItem('selectedRows') || '[]') as any[];
    const totalAmount = Number(localStorage.getItem('totalAmount') || '0');
    const pendingModalOpen = localStorage.getItem('pendingModalOpen') === 'true';
    const installmentModalOpen = localStorage.getItem('installmentModalOpen') === 'true';
    const isAllPaymentModalOpen = localStorage.getItem('isAllPaymentModalOpen') === 'true';
    this.setState({
      isAllPaymentModalOpen:isAllPaymentModalOpen,
      isPaymentResponseModalOpen:false,
      selectedRows: selectedRows,
      totalAmount: pendingModalOpen ? totalAmount : 0,
      subtotal: pendingModalOpen ? 0 : totalAmount,
      pendingModalOpen: pendingModalOpen,
      isInstallmentModalOpen:installmentModalOpen,
      installmentAmount: installmentModalOpen && totalAmount
    })
  }

  handleRefundPendingModalOpen = (amount:string) => {
    const updatedData = this.state.orderTransactionData.filter((item)=>
    String(item.attributes.status)?.toUpperCase() === "PENDING" && this.state.creditStatus)
    this.setState({
      isRefundModal:true,
      refundTotalAmount:Number(amount),
      selectedRows:updatedData,
      refundPendingModalOpen:true
    })
  }

  handleAllPaymentModalOpen = (id: string, amount: string) => { 
      const updatedData = this.state.orderTransactionData.filter((item) =>
      item.attributes.ns_internal_id === id 
    );
        this.setState({
          isAllPaymentModalOpen:true,
          selectedRows:updatedData,
          subtotal:Number(amount),
          pendingModalOpen:false
      }, () => {
        localStorage.setItem('isAllPaymentModalOpen', JSON.stringify(true));
        localStorage.setItem('totalAmount', JSON.stringify(Number(amount)));
        localStorage.setItem('selectedRows', JSON.stringify(updatedData));
        localStorage.setItem('pendingModalOpen', JSON.stringify(false));
      })
  }

  handleAllPaymentModalClose = () => {
    this.setState({
      isAllPaymentModalOpen:false, 
      pendingModalOpen:false, 
      showAllTransactions:false
    }, () => {
      localStorage.removeItem('isAllPaymentModalOpen');
      localStorage.removeItem('totalAmount');
      localStorage.removeItem('selectedRows');
      localStorage.removeItem('pendingModalOpen');
      localStorage.removeItem('paymentIds')
    });
  }

  handleShowTransactions = () => this.setState({showAllTransactions:!this.state.showAllTransactions})

  handlePaymentModalNext = () =>{
    const pendingTransactionIds = this.state.totalPendingTransactions.map((item:any)=>Number(item.id)) 
    const selectedRows = this.state.selectedRows.map((item:any) => Number(item.attributes.ns_internal_id))
    const Id = selectedRows.length > 0 ? selectedRows : pendingTransactionIds
    this.generatePaymentApiCall(false, 
     0, pendingTransactionIds
    )    

    localStorage.setItem('paymentIds', JSON.stringify(Id)) 
  }

  handleRefundModalOpen = (id:string, amount:string) =>{
    const updatedData = this.state.orderTransactionData.filter((item) =>
    item.attributes.ns_internal_id === id 
  );
    this.setState({
      isRefundModal:true, 
      selectedRows:updatedData,
      refundSubtotal:Number(amount),
      refundPendingModalOpen:false
    })
  }  
  
  handleRefundModalClose = () => {
    this.setState({
      isRefundModal:false, 
      beneficiarysName:"",
      banksName:"",
      bankCode:"",
      branchCode:"",
      swiftCode:"",
      accountNumber:"",
      contactNumber:"",
      payNowId:"",
      payNowName:"",
      contactNumberError:"",
      banksNameError:"",
      swiftCodeError:"",
      accountNumberError:"",
      beneficiarysNameError:"",
      payNowIdError:"",
      payNowNameError:"",
      refundPaymentMethod:"by_bank_transfer",
      refundPendingModalOpen:false,
      bankCodeError:"",
      branchCodeError:""
    })
  }
    
  handleInstallmentsModalOpen = (id:string, amount:string) => {
    const updatedData = this.state.orderTransactionData.filter((item) =>
      item.attributes.ns_internal_id === id) 
    this.setState({
      isInstallmentModalOpen:true, 
      installmentAmount:amount,
      installmentId:id, 
      selectedRows:updatedData
    }, ()=>{
      localStorage.setItem('totalAmount', JSON.stringify(Number(amount)));
      localStorage.setItem('selectedRows', JSON.stringify(updatedData));
      localStorage.setItem('installmentModalOpen', JSON.stringify(true));
    })
  }
  handleInstallmentsModalClose = () => {
    this.setState({
      isInstallmentModalOpen:false, 
      payingAmount:"", 
      payingAmountError:""
    },()=>{
      localStorage.removeItem('totalAmount');
      localStorage.removeItem('selectedRows');
      localStorage.removeItem('installmentModalOpen');
      localStorage.removeItem('paymentIds');
    })
  }
  handleRefundPaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      refundPaymentMethod: event.target.value,
      beneficiarysName:"",
      banksName:"",
      bankCode:"",
      branchCode:"",
      swiftCode:"",
      accountNumber:"",
      contactNumber:"",
      payNowId:"",
      payNowName:"",
      contactNumberError:"",
      banksNameError:"",
      swiftCodeError:"",
      accountNumberError:"",
      beneficiarysNameError:"",
      payNowIdError:"",
      payingAmountError:"",
      payNowNameError:"",
      bankCodeError:"",
      branchCodeError:""
    })

  }

  setPayingAmount = (text: string) => {
  let payAmt = text.replace(/[^\d.]/g, '')  
                   .replace(/(\..*)\./g, '$1')  
                   .replace(/^0+(?=\d)/, ''); 

  const parts = payAmt.split('.');
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].substring(0, 2);
    payAmt = parts.join('.');
  }
    this.setState({ payingAmount: payAmt, payingAmountError:"" });
  };

  setPayNowId = (text: string) => {
    this.setState({ payNowId: text, payNowIdError:"" });
  };

  setPayNowName = (text: string) => {
    this.setState({ payNowName: text, payNowNameError:"" });
  }

  setContactNumber = (text: string, country:any) => {
    this.setState({ contactNumber: text, contactNumberFormat: country.format, contactNumberError:""});
  }
  
  setBeneficiarysName = (text:string) => {
    this.setState({ beneficiarysName: text, beneficiarysNameError:""});
  }

  setBanksName = (text:string) => {
    this.setState({ banksName: text, banksNameError:""});
  }

  setAccountNumber = (text:string) => {
    this.setState({ accountNumber: text, accountNumberError:""});
  }

  setSwiftCode = (text:string) => {
    this.setState({ swiftCode: text, swiftCodeError:""});
  }

  setBranchCode = (text:string) => {
    this.setState({ branchCode: text, branchCodeError:""});
  }

  setBankCode = (text:string) => {
    this.setState({bankCode: text, bankCodeError:""});
  }

  validateInstallmentForm = () => {
    const { payingAmount, installmentAmount } = this.state;
    let payingAmountError = "";
  
    if (!payingAmount) {
      payingAmountError = configJSON.payingAmountRequiredError;
    } else if (isNaN(Number(payingAmount)) || Number(payingAmount) <= 0) {
      payingAmountError = configJSON.payingAmountGreaterError;
    } else if (Number(payingAmount) > Number(installmentAmount)) {
      payingAmountError = configJSON.payingAmountLesserError;
    }
  
    this.setState({ payingAmountError });
    
    return !payingAmountError;
  };

  handleInstallmentSubmit = () => {
    const { payingAmount, installmentId } = this.state;
    if(this.validateInstallmentForm()){   
      this.generatePaymentApiCall(true, Number(payingAmount))
      localStorage.setItem('paymentIds', JSON.stringify(Number(installmentId)))
      this.setState({
        payingAmountError: "",
      });
    }
  };

  handleDownloadClick = (url:string, transactionType: string, transactionId:number) => {
    
    if(url){
      const downloadFileName = `${transactionType}-${transactionId}.pdf`
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      
      xhr.onload = () => {
        const blob = new Blob([xhr.response], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = downloadFileName;
          link.click();
      }
      xhr.send();
      
    }
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleValidPhone = () => {
    const { contactNumber, contactNumberFormat } = this.state;
    let phoneLengthCount = 0;
    for(let char of contactNumberFormat) {
      if(char ==="."){
        phoneLengthCount++
      }
    }

    if (this.isStringNullOrBlank(this.state.contactNumber)) {
      return false;
    }

    if(contactNumber.length === phoneLengthCount) {
      return true;
    } 
    else {
      return false
    }
  
  }

  validateForm = () => {
    const {
      beneficiarysName,
      banksName,
      accountNumber,
      swiftCode,
      bankCode,
      branchCode
    } = this.state;
  
    const errors = {
      beneficiarysNameError: beneficiarysName ? "" : configJSON.beneficiarysNameError,
      banksNameError: banksName ? "" : configJSON.banksNameError,
      accountNumberError: accountNumber ? "" : configJSON.accountNumberError,
      swiftCodeError: swiftCode ? "" : configJSON.swiftCodeError,
      contactNumberError: this.handleValidPhone() ? "" : configJSON.contactNumberError,
      bankCodeError: bankCode ? "" : configJSON.bankCodeError,
      branchCodeError: branchCode ? "" : configJSON.branchCodeError
    };
  
    this.setState(errors);
  
    const isValid = !Object.values(errors).some(error => error);
    return isValid;
  };

  handleByBanksTransferSubmit = () =>{
    const { 
      refundSubtotal, 
      refundPendingModalOpen, 
      refundTotalAmount, 
      refundPaymentMethod, 
      beneficiarysName, 
      banksName, 
      accountNumber, 
      swiftCode, 
      bankCode, 
      branchCode, 
      contactNumber 
    } = this.state;

    if(this.validateForm()){
      const formData = new FormData();
      const selectedRows = this.state.selectedRows.map((item:any) => item.attributes.ns_internal_id)
      const amount = refundPendingModalOpen ? refundTotalAmount : refundSubtotal;
      
      formData.append("refund_request[ns_internal_id]", selectedRows?.toString())
      formData.append("refund_request[refund_amount]", amount?.toString())
      formData.append("refund_request[beneficiary_name]", beneficiarysName)
      formData.append("refund_request[bank_name]", banksName)
      formData.append("refund_request[bank_code]", bankCode)
      formData.append("refund_request[branch_code]", branchCode)
      formData.append("refund_request[swift_code]", swiftCode)
      formData.append("refund_request[account_number]", accountNumber)
      formData.append("refund_request[contact_number]", contactNumber)
      formData.append("refund_request[refund_type]", refundPaymentMethod)
      
      this.generateRefundApiCall(formData) 
   }
  }

  validatePayNowForm = () => {
    const {
      payNowId,
      payNowName,
    } = this.state;
  
    const errors = {
      payNowIdError: !payNowId || isNaN(Number(payNowId)) ? configJSON.payNowIdError : "",
      payNowNameError: payNowName ? "" : configJSON.payNowNameError,
      contactNumberError: this.handleValidPhone() ? "" : configJSON.contactNumberError
    };
  
    this.setState(errors);
  
    const isValid = !Object.values(errors).some(error => error);
    return isValid;
  };

  handlePayNowSubmit = () => {
    const {
      refundPaymentMethod, 
      refundPendingModalOpen, 
      refundTotalAmount, 
      refundSubtotal, 
      payNowId, 
      payNowName, 
      contactNumber
    } = this.state

    if(this.validatePayNowForm()){
      const form = new FormData();
      const selectedRows = this.state.selectedRows.map((item:any) => item.attributes.ns_internal_id)
      const amount = refundPendingModalOpen ? refundTotalAmount : refundSubtotal;

      form.append("refund_request[refund_amount]", amount?.toString())
      form.append("refund_request[refund_type]", refundPaymentMethod)
      form.append("refund_request[ns_internal_id]", selectedRows?.toString())
      form.append("refund_request[paynowid_name]", payNowId)
      form.append("refund_request[paynow_name]", payNowName)
      form.append("refund_request[contact_number]", contactNumber)
      this.generateRefundApiCall(form)
    }
  };
}
  // Customizable Area End
  
