import {
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
} from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { BootstrapInputStyle } from '../../blocks/utilities/src/Colors';

const style = {
    ModalWrapperStyles: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: '400px',
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: "5px 30px",
        width:"100%",
        borderRadius: "8px 8px 32px 8px",
        "@media(max-width: 767px)": {
            width: '70%'
          },
    },
    modalHeaderStyle: {
        display: "flex",
        flexDirection: "row" as 'row',
        justifyContent: "space-between",
        alignItems: "center" as 'center',
        height: "80px",
        borderBottom: "1px solid #ECEBEB",
        marginBottom: "30px",
    },
    headerTitleStyle: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "left" as "left",
    },
    closeIconStyle: {
        width: "32px",
        height: "32px",
        color: "rgb(218, 41, 28)",
    },
    middleContainer: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #ECEBEB",
        paddingBottom: "25px"
    },
    transactionIDStyle: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        textAlign: "left" as "left",
    },
    totalAmountStyle: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left" as "left",
        color: "#7D7A78",
    },
    inputLabelTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left",
        marginTop: "20px",
        marginBottom: "6px"
    },
    astrixStyle: {
        color: "rgb(218, 41, 28)"
    },
    errorStyle: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left' as 'left',
        color: '#DC2626'
    },
    bottomContainerStyle: {
        display: "flex",
        justifyContent: "space-between",
        margin: "25px 0px",
        paddingTop: "20px",
        borderTop: '1px solid rgb(236, 235, 235)'
    },
    remainingAmountLabelStyle: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        textAlign: "left" as "left",
        color: "#7D7A78",
    },
    remainingAmountStyle: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        textAlign: "right" as "right",
    },
    nextBtnStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left",
        color: '#FFFFFF',
        padding: "10px 16px",
        backgroundColor: '#DA291C'
    },
    paymentTextWrapper: {
        display: "flex", 
        justifyContent: "center",
        paddingBottom: "1px" 
    },
    paymentTxt:{
        fontSize: "12px", 
        fontWeight: 500, 
        color: "black"
    },
    linkStyle:{
        textDecoration: "underline",
        cursor: "pointer",
        color:"black"
    },
};

interface InstallmentsModalProps {
    isInstallmentsModalOpen: boolean;
    installmentAmount:string;
    handleInstallmentModalClose: () => void;
    setPayingAmount: (event: string) => void;
    payingAmount: string | number;
    payingAmountError: string;
    handleSubmit: () => void;
    selectedRows: any[];
}
const InstallmentsModal: React.FC<InstallmentsModalProps> = ({
    isInstallmentsModalOpen,
    handleInstallmentModalClose,
    setPayingAmount,
    installmentAmount,
    handleSubmit,
    payingAmount,
    payingAmountError,
    selectedRows
}) => {
    
    const remainingAmount = Number(installmentAmount)-Number(payingAmount)
    const document_number = selectedRows.map((item)=> item.attributes.document_number)
    
    return (
        <Box>
            <Modal data-test-id="installment-modal-open-id" open={isInstallmentsModalOpen}>
                <Box sx={style.ModalWrapperStyles}>
                    <Box style={style.modalHeaderStyle}>
                        <Typography
                            id="modal-modal-title"
                            style={style.headerTitleStyle}
                        >
                            Installment Payment
                        </Typography>
                        <IconButton style={{padding:"0px"}} data-test-id="installment-modal-closeicon-id" onClick={handleInstallmentModalClose}>
                            <CloseIcon
                                style={style.closeIconStyle}
                            />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box style={style.middleContainer}>
                            <div style={style.transactionIDStyle}>
                                {document_number}
                                <div style={style.totalAmountStyle}>
                                    Transaction ID
                                </div>
                            </div>
                            <div style={style.transactionIDStyle}>
                                S${installmentAmount}
                                <div style={style.totalAmountStyle}>
                                    Total Amount
                                </div>
                            </div>
                        </Box>
                        <Box>
                            <Box style={style.inputLabelTitle}>
                                Paying Amount
                                <span style={style.astrixStyle}>*</span>
                            </Box>
                            <BootstrapInputStyle data-test-id="payingAmountId" error={!!payingAmountError} fullWidth placeholder="Enter Paying Amount" value={payingAmount}
                                onChange={(e) => setPayingAmount(e.target.value)} />
                            {payingAmountError && (
                                <Typography style={style.errorStyle}>
                                    {payingAmountError}
                                </Typography>
                            )}
                            <Box style={style.bottomContainerStyle}>
                                <div style={style.remainingAmountLabelStyle}>
                                    Remaining Amount
                                </div>
                                <div style={style.remainingAmountStyle}>
                                S$ {remainingAmount >= 0 ? remainingAmount.toFixed(2) : 0}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={style.paymentTextWrapper}>
                        <Typography style={style.paymentTxt} >
                            Please refer to the <a href="https://www.cis.edu.sg/admissions/fees" target="_blank" style={style.linkStyle}>link</a> for other payments methods.
                        </Typography>
                    </Box>
                    <Box padding="10px 0px">
                        <Button
                            data-test-id="installmentSubmitId"
                            fullWidth
                            style={style.nextBtnStyle}
                            onClick={handleSubmit}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default InstallmentsModal