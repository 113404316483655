import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area Start
import { createStyles, withStyles } from "@material-ui/core/styles"
import { styled } from "@material-ui/styles";
import { arrowback, studentimg,arrowdown, check, attachFile, contactUsStudentsImage, hamburgerIcon } from './assets'
import { InputBase, TextareaAutosize } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css';
import IconButton from '@material-ui/core/IconButton';
export const configJSON = require("./config");
import CloseIcon from '@material-ui/icons/Close';
import { SideBarNavigation } from "../../../components/src/SideNavbar.web";
import ProfileModal from "../../../components/src/ProfileModal.web";
import Loader from "../../../components/src/Loader.web";
import StatusNotifyModal from "../../../components/src/StatusNotifyModal.web";

// Customizable Area End
import ContactusController, { Props } from "./ContactusController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area Start
export const BootstrapInputStyle = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    '& input': {
      borderColor: '#E67067',
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E0DE',
    padding: '20px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },

}))(InputBase);




// Customizable Area End
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          {/* Customizable Area Start */}
          <MainContainer>
          <Box className="container">
            {this.state.sideBarOpen && <SideBarNavigation handleNav={this.handleBlockPathNavigation} />}
            <Box className={classes.content}>
              <div className="payment-header">
                <div className="payment-header-icon">
                  <img data-test-id="sideBarToogleId" onClick={()=>this.handleToggleSideBar()} src={hamburgerIcon} alt="hamburgerIcon" style={{ width: "20px", height: "20px"}}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img data-test-id="arrowBckImgId" onClick={() => this.handleNavigateToPreviousScreen()}style={{ width: '24px', height: '24px', cursor:'pointer' }} src={arrowback} />
                  <div style={{ marginLeft: '5px', color: '#3D3935', fontSize: 24, fontFamily: 'Inter', fontWeight: 700 }}>Contact Us</div>
                </div>
                <div data-test-id="usrerModalOptionId" className={classes.headerUserOption} onClick={() => this.handleIsUserOtherOptionModalOpen()}>
                  <img 
                    src={this.state.headerProfilePic ? this.state.headerProfilePic : studentimg} 
                    style={{ width: '40px', height: '40px', borderRadius: '50%', border: '3px #ECEBEB solid', }} 
                  />
                  <div 
                    style={{ 
                      maxWidth: "150px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden",
                      marginLeft: '5px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, margin: "0px 8px" 
                    }}
                  >Hi, {this.state.userName}</div>
                  <img style={{ width: '20px', height: '20px' }} src={arrowdown} />
                </div>
              </div>

              <ProfileModal
                data-test-id="RenderUserModalOptionWrapperID"
                handleNavigationPath={(path:string)=> this.handleBlockPathNavigation(path)}
                isProfileModalOpen={this.state.isUserOptionModalOpen}
                handleUserModalOptionOpen={this.handleIsUserOtherOptionModalOpen}
              />
              <StatusNotifyModal 
                isModalOpen={this.state.isSessionExpireModalOpen}
                modalMessage={this.state.sessionExpireMessage}
                isSessionExpired={this.state.isSessionExpire}
                handleNavigationPath={(path: string) => this.handleIsSessionExpired(path)}
              />
              {this.state.showLoader && <Loader loading={true}/>}
              <Box style={{ paddingLeft: '50px', paddingTop: '20px' }}>
                <Grid className={classes.body} container spacing={1} direction="row" justifyContent="space-between">
                  <Grid className={classes.form} item xs={12} md={6} lg={7} xl={8}>
                    <Box style={{ color: '#3D3935', fontSize: 30, fontFamily: 'Inter', fontWeight: 700 }}>Talk With Us</Box>
                    <Box style={{ marginTop: '12px', marginBottom: '32px', color: '#7D7A78', fontSize: 16, fontFamily: 'Inter', fontWeight: 400 }}>Fill in the form below, and we will get back to you as soon as possible!</Box>
                    <Box style={{ color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Name</Box>
                    <BootstrapInputStyle fullWidth placeholder="Enter your name" value={this.state.name} data-test-id={"txtName"}
                      onChange={(e) => this.setNameInput(e.target.value)} />
                    {this.state.fieldErrorName && (
                      <Typography style={{ color: "red", fontFamily: 'Inter', fontSize: 14, }}>
                        {configJSON.errorNameNotValid}
                      </Typography>
                    )}
                    <Box style={{ marginTop: '16px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Email address</Box>
                    <BootstrapInputStyle fullWidth placeholder="Enter your email address" value={this.state.email} data-test-id={"txtEmail"}
                      onChange={(e) => this.setEmailInput(e.target.value)} />
                    {this.state.fieldError && (
                      <Typography style={{ color: "red", fontFamily: 'Inter', fontSize: 14, }}>
                        {configJSON.errorEmailNotValid}
                      </Typography>
                    )}
                    <Box style={{ marginTop: '16px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Contact number</Box>
                    <Box className={classes.customPhoneInput}>
                      <PhoneInput country={'sg'} value={this.state.phoneNumber} data-test-id={"txtPhone"}
                        onChange={(value, country) => this.setPhoneInput(value, country)}
                      />
                      {this.state.fieldErrorPhone && (
                        <Typography style={{ color: "red", fontFamily: 'Inter', fontSize: 14, }}>
                          {this.state.phoneErrorMessage}
                        </Typography>
                      )}
                    </Box>
                    <Box style={{ marginTop: '20px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Subject</Box>
                    <BootstrapInputStyle fullWidth placeholder="What do you want to talk about?" value={this.state.subject} data-test-id={"txtSubject"}
                      onChange={(e) => this.setSubject(e.target.value)} />
                    <Box style={{ marginTop: '16px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', fontWeight: 700, marginBottom: '4px' }}>Details</Box>
                    
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <TextareaAutosize 
                        data-test-id={"txtDetails"} 
                        placeholder="Any details you would like to share?" 
                        className={classes.customTextArea} minRows={4} maxRows={4} 
                        value={this.state.Detail}
                        onChange={(e) => this.setDetail(e.target.value)} 
                      />
                      <Box className={classes.fileUpload}>
                        <label htmlFor="uploadAttachmentsFile">
                        <input
                          type="file"
                          multiple
                          id="uploadAttachmentsFile"
                          data-test-id="upload-files"
                          onChange={(event) => this.handleAttachmentsUpload(event)}
                          style={{ display: 'none' }}
                          name="uploadAttachmentsFile"
                        />
                          <img src={attachFile} style={{ marginLeft: '6px', cursor:'pointer' }}/>
                        </label>
                      </Box>
                    </Box>
                      { this.state.uploadedAttachments && this.state.uploadedAttachments.length > 0 &&
                        <Box className={classes.uploadedAttachmentsBox}>
                          {
                            this.state.uploadedAttachments.map((file: any, index: number) => {
                              return <Chip 
                                key={`chip-delete-${index}`} 
                                data-test-id={`chip-delete-${index}`} 
                                className={classes.chipStyle} 
                                label={file.name} 
                                onDelete={() => this.handleAttachmentsDelete(file.name)}
                              />
                            })
                          }

                        </Box>
                      }
                    <Box className={classes.sendMessageBtnWrapper}>
                      <Box data-test-id={"txtsubmit"} style={{ color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: 700 }} onClick={() => this.submit()}>Send Message</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={8} md={6} lg={5} xl={4} style={{ textAlign: 'end' }}>
                    <img src={contactUsStudentsImage} className={classes.studentImage} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Modal data-test-id="successModalId" className={classes.modal} open={this.state.openModal} onClose={() => this.handleClose()}>
              <Box sx={modalStyle}>
                <IconButton aria-label="Close" data-test-id="successModalIconCloseId" className={classes.closeButton} onClick={() => this.handleClose()}>
                  <CloseIcon />
                </IconButton>
                <div style={{textAlign:"center",marginTop:"50px"}}>
                <img src={check} style={{ width: '15%'}}/>
                <div style={{marginTop:"20px"}}>Your message has been successfully sent</div>
                </div>
                </Box>
            </Modal>
          </Box>
          </MainContainer>
          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const webStyle = createStyles({
  "MuiContainer-root": {
    padding: '0 !important'
  },
  "MuiContainer-maxWidthMd": {
    maxWidth: '100%'
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    '& *': {
      fontFamily: "'Inter', sans-serif",
      boxSizing: 'border-box'
    },
  },
  navbar: {
    width: 120,
    height: '100vh',
    padding: '0px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px #ECEBEB solid'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 40px 24px 0px',
    paddingLeft: '48px'
  },
  form: {
    padding: '28px 0px !important',
    paddingRight: "25px !important"
  },
  errorMsg: {
    color: "red",
    margin: 10,
  },
  body: {
    '@media (max-width: 680px)': {
      width: '80%',
    }
  },
  content: {
    flex: '1',
    overflowY: 'auto',
    height: "100vh",
    overflowX: 'hidden'
  },
  customPhoneInput: {
    width: '100%',
    '& .form-control': {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      fontSize: 16,
      width: '100%',
      borderRadius: 8,
      border: '1px solid #E0E0DE',
      paddingTop: '10px',
      paddingBottom: '10px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      ' &:focus': {
        borderColor: theme.palette.primary.main,
      },
    }
  },
  customTextArea: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    border: '1px solid #E0E0DE',
    padding: '20px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    ' &:focus': {
      borderColor: theme.palette.primary.main,
    },

  },
  modal: {
    '& div:first-child': {
      backgroundColor: 'rgba(255, 255, 255, 0.90) !important'
    }
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: 'red',
  },
  uploadedAttachmentsBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '5px',
    marginTop: '10px',
    padding: '5px 0px',
    height: '90px',
    overflowY: 'auto',
  },
  chipStyle: {
    borderRadius: '8px',
    width: 'auto',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #E0E0DE',
    maxWidth: "230px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerUserOption: { 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'flex-end', 
    cursor: 'pointer', 
    position: "relative",
    backgroundColor: '#F1F5F9',
    padding: '10px 12px',
    borderRadius: '8px',
    '@media (max-width: 680px)': {
      justifyContent: 'center',
    },
  },
  studentImage: {
    height: 'auto', 
    width: "100%",
    borderRadius: '0 0 0 60px',
    '@media (max-width: 680px)': {
      minWidth: '400px', 
      height: '400px', 
    },
    '@media (max-width: 480px)': {
      minWidth: '200px', 
      height: '200px', 
    }
  },
  sendMessageBtnWrapper: { 
    marginTop: '32px', 
    cursor: 'pointer', 
    background: '#DA291C', 
    borderRadius: 8, 
    paddingLeft: 16, 
    paddingRight: 16, 
    paddingTop: 10, 
    paddingBottom: 10, 
    gap: 8, 
    overflow: 'hidden', 
    display: 'inline-flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
  },
})

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 200,
  bgcolor: 'background.paper',
  borderBottom: `1px solid ${theme.palette.divider}`,
  margin: 0,
  boxShadow: 24,
  p: 4,
  outline: 'none',
  borderBottomRightRadius: 20,
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "'Inter', sans-serif"
};

const MainContainer = styled(Box)({
  width: '100%',
  "& .container": {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100vh',
    display: "flex",
    '& *': {
      fontFamily: "'Inter', sans-serif",
      boxSizing: 'border-box'
    },
    "@media(max-width: 992px)": {
      position: 'unset'
    }
  },
  "& .payment-header": {
    flexWrap: 'wrap',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 40px 24px 0px',
    paddingLeft: '48px',
    "@media(min-width: 768px)": {
      '& .payment-header-icon': {
        display: 'none',
      }
    },
    "@media(max-width: 767px)": {
      gap: '10px',
      paddingLeft: "40px !important", 
      '& .payment-header-icon': {
        display: 'flex',
        position: 'absolute',
        left: '13px',
        top: '42px',
        cursor: 'pointer',
       
      }
    },
    "@media(max-width: 500px)": {
      padding: '24px 40px 24px 24px',
      cursor: 'pointer',
      paddingTop: '37px !important',
    }
  },
})

export const ContactusBlock = withStyles(webStyle)(Contactus)

// Customizable Area End
