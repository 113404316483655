Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.headerInfo =
  "This is your profile, Here you can see and update your personal information.";
exports.btnTxtUploadFromStorage = "UPLOAD FROM STORAGE";
exports.btnTxtUploadFromCamera = "UPLOAD FROM CAMERA";
exports.btnTxtDelete = "DELETE IMAGE";
exports.btnTxtSave = "SAVE CHANGES";
// exports.placeHolder = require("../assets/placeholder.jpg");

exports.photoApiEndPoint =
  "profile/photo";

exports.photoUpdateApiMethod = "PUT";
exports.photoUpdateApiContentType = "multipart/form-data";

exports.photoGetApiContentType = "application/json";
exports.photoGetApiMethod = "GET";
