Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "multipart/form-data";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfnetsuiteintegrationpaymentstatusupdate";
exports.labelBodyText = "cfnetsuiteintegrationpaymentstatusupdate Body";
exports.btnExampleTitle = "CLICK ME";

exports.orderTransactionApiEndPoint = "bx_block_filter_items/filtering";
exports.getProfileDetailsApiEndPoint = "account_block/accounts/1/get_profile";
exports.getSoaDataApiEndPoint = "bx_block_filter_items/filtering/soa_transactions";
exports.getTransactionDataIdApiEndpoint = "bx_block_filter_items/filtering/search";
exports.getCardupCustomerResponseEndpoint = "bx_block_cfcarduppaymentgatewayintegration/cardup_recipients/create_cardup_customer";
exports.generatePaymentApiEndPoint = "bx_block_cfcarduppaymentgatewayintegration/payment"
exports.prodApiEndPoint = "https://app.cardup.co/api/v1/checkouts";
exports.sandboxApiEndPoint = "https://sandbox.cardup.co/api/v1/checkouts";
exports.generateRefundApiEndPoint = "bx_block_cfnetsuiteintegrationpaymentstatusupdate/refund"
exports.getSyncTransactionEndPoint = "bx_block_cfcarduppaymentgatewayintegration/payment/sync_transaction"
exports.getPaymentStatusResponseMessage = "bx_block_cfcarduppaymentgatewayintegration/payment/show_transaction"

exports.payingAmountRequiredError = "Paying amount is required"
exports.payingAmountGreaterError = "Amount must be a number greater than zero"
exports.payingAmountLesserError = "Paying amount must be less than or equal to installment amount"

exports.beneficiarysNameError = "Enter your name"
exports.banksNameError = "Enter bank name"
exports.accountNumberError = "Enter valid account number"
exports.swiftCodeError = "Enter valid code"
exports.contactNumberError = "Enter valid contact number"
exports.bankCodeError = "Enter bank code"
exports.branchCodeError = "Enter branch code"

exports.payNowIdError = "Enter valid ID"
exports.payNowNameError = "Enter name"

exports.sessionExpiredMessage = "Session is expired please login again!"
exports.paymentText = "Payment"
// Customizable Area End